import React from "react";
import "./bottomContentPage.css";
function BottomContentPage({
  insuranceType,
  firstContent,
  firstImage,
  secondContent,
  secondImage,
}) {
  return (
    <div>
      <div
        style={{
          margin: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <p className="list-headline" style={{ alignSelf: "center" }}>
          Why Buy From <strong>Expert Cover?</strong>
        </p>
        <div className="card-container">
          <div className="benefit-card">
            <div className="img-circle">
              <img src="./assets//img/medal.png" alt="" />
            </div>
            <div>
              <h2>
                Platinum insurance <br />
                partner
              </h2>
              <p className="card-txt">
                It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages
              </p>
            </div>
          </div>
          <div className="benefit-card">
            <div className="img-circle" style={{ backgroundColor: "#FFF3DD" }}>
              <img src="./assets/img/money-bag 1.png" alt="" />
            </div>
            <div>
              <h2>Affordable options</h2>
              <p className="card-txt">
                It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages
              </p>
            </div>
          </div>
          <div className="benefit-card">
            <div className="img-circle" style={{ backgroundColor: "#FBF1FF" }}>
              <img src="./assets/img/customer-service 1.png" alt="" />
            </div>
            <div>
              <h2>30 minute claim support*</h2>
              <p className="card-txt">
                It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="content-and-img">
        <div className="bike-insurance-content" style={{ flex: "40%" }}>
          <p className="list-headline">
            Know More About <strong>{insuranceType}</strong>
          </p>
          <ul>
            {firstContent.map((res) => (
              <>
                <li>{res}</li>
                <br />
              </>
            ))}
          </ul>
        </div>
        <img
          src={firstImage}
          alt=""
          height={"50%"}
          width={"50%"}
          style={{ flex: "40%" }}
        />
      </div>

      <div className="img-and-content">
        <img
          src={secondImage}
          alt=""
          height={"30%"}
          width={"40%"}
          style={{ flex: "40%" }}
        />

        <div
          className="bike-insurance-content"
          style={{ flex: "40%", marginLeft: "10%" }}
        >
          <p className="list-headline">
            Benefits of <strong>{insuranceType}</strong>
          </p>
          <ul>
            {secondContent.map((res) => (
              <>
                <li>{res}</li>
                <br />
              </>
            ))}
          </ul>
        </div>
      </div>

      <div className="disclaimer">
        <h1>Disclaimer</h1>
        <p className="disclaimer-content">
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages and web
          page editors now use Lorem Ipsum as their default model text, and a
          search for 'lorem ipsum' will uncover many web sites still in their
          infancy. Various versions have evolved over the years, sometimes by
          accident, sometimes on purpose (injected humour and the like).
        </p>
      </div>
    </div>
  );
}

export default BottomContentPage;

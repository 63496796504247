import React from 'react'
import Layout from '../../common/Layout'
import './bike.css'
import BottomContentPage from '../../common/BottomContentPage/BottomContentPage'
import FormTabs from '../../common/FormTabs/FormTabs'

const Bike = () => {
  const firstContent = [
    'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock',
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary",
    'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum',
    'If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. There is no minimum donation, any sum is appreciated'
  ]
  const secondContent = [
    'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock',
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary",
    'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum',
    'If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. There is no minimum donation, any sum is appreciated'
  ]
  const firstImage = './assets/img/Screenshot 2023-07-26 130644.png'
  const secondImage = './assets/img/medical.png'

  return (
    <Layout pageClass={'bikepage'}>
      <div className='sections-container'>
        <div className='img-and-form'>
          <img
            src='./assets/img/Group (1).png'
            alt='failed-to-load'
            height={'40%'}
            width={'40%'}
          />

          <FormTabs vehicleType={'2w'} />
        </div>

        <BottomContentPage
          insuranceType={'Bike Insurance'}
          firstContent={firstContent}
          firstImage={firstImage}
          secondContent={secondContent}
          secondImage={secondImage}
        />
      </div>
    </Layout>
  )
}

export default Bike

// export const GetQuoteButton = ({ link, product }) => {
//   const navigate = useNavigate();
//   return (
//     <div className="mt-lg-5 pt-4 pb-4">
//       <a
//         href="javascript:void(0)"
//         onClick={() =>
//           navigate(link, {
//             state: { product: product },
//           })
//         }
//         className="btn btn-primary fs-4 px-4 py-3 me-3"
//       >
//         <i className="fa-solid fa-minus me-3" />
//         Get Quote
//       </a>
//       {/* <a
//         href="#!"
//         className="btn border border-1 border-primary fs-4 ms-3 text-capitalize px-5 py-3 fw-normal text-primary second"
//       >
//         See Details
//       </a> */}
//     </div>
//   );
// };

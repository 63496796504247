import React from "react";
import Layout from "../../common/Layout";
import { TabContent, TabPane } from "reactstrap";
import ReactSelect from "react-select";
import "./health.css";
import BottomContentPage from "../../common/BottomContentPage/BottomContentPage";

const Health = () => {
  const [activeTab, setActiveTab] = React.useState(1);
  const toggle = (tab) => setActiveTab(tab);
  const [state, setState] = React.useState([]);
  const firstContent = [
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock",
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary",
    "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum",
    "If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. There is no minimum donation, any sum is appreciated",
  ];
  const secondContent = [
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock",
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary",
    "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum",
    "If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. There is no minimum donation, any sum is appreciated",
  ];
  const firstImage = "./assets/img/Group.png";
  const secondImage = "./assets/img/Frame.png";

  return (
    <Layout pageClass={"healthpage"}>
      <>
        <div className="frame-box col-lg-12">
          <div className="image-and-form">
            <div className="ecllipse-box col-lg-6">
              <img src="./assets/img/health_img_circle.png" id="circle" />
              <img src="./assets/img/health_family.png" id="family" />
            </div>
            <div className="insurance-info col-lg-6">
              {activeTab === 1 || activeTab === 2 || activeTab === 3 ? (
                <h1>
                  Find top health insurance plans for you with up to{" "}
                  <span>25% discount**</span>
                </h1>
              ) : (
                ""
              )}
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <img src="./assets/img/progress_1.png" id="progress_1" />

                  <h2 id="tab1Head">Who would you like to insure?</h2>
                  <div className="radioContainer col-lg-12 row">
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Self</p>
                    </div>
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Spouse</p>
                    </div>
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Son</p>
                    </div>
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Father</p>
                    </div>
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Daughter</p>
                    </div>
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Mother</p>
                    </div>
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Grand Father</p>
                    </div>
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Grand Mother</p>
                    </div>
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Father-in-Law</p>
                    </div>
                    <div className="radioBox col-lg-6">
                      <input type="radio" />
                      <p>Mother-in-Law</p>
                    </div>
                  </div>
                  <button
                    className="tab1Btn"
                    onClick={() => toggle(activeTab + 1)}
                  >
                    Continue
                  </button>
                </TabPane>

                <TabPane tabId={2}>
                  <img src="./assets/img/progress_2.png" id="progress_1" />
                  <p id="tab2label1">How old is the member?</p>
                  <ReactSelect
                    value={[1, 2, 3, 4]}
                    options={[1, 2, 3, 4]}
                    name="age"
                    placeholder="Select Your Age"
                    style={{ borderRadius: "0.625rem", width: "33.8125rem" }}
                  />
                  <p id="tab2label1">Where do you live?</p>
                  <ReactSelect
                    value={[1, 2, 3, 4]}
                    options={[1, 2, 3, 4]}
                    name="age"
                    placeholder="Select Your Age"
                    style={{ borderRadius: "0.625rem", width: "33.8125rem" }}
                  />
                  <button
                    className="tab1Btn"
                    onClick={() => toggle(activeTab + 1)}
                  >
                    Continue
                  </button>
                </TabPane>
                <TabPane tabId={3}>
                  <div className="tab3">
                    <img src="./assets/img/progress_3.png" id="progress_1" />
                    <p id="tab2label1">Full Name</p>
                    <input type="text" />
                    <p id="tab2label1">Enter Mobile Number</p>
                    <input type="text" />
                    <button
                      className="tab1Btn"
                      onClick={() => toggle(activeTab + 1)}
                    >
                      Continue
                    </button>
                  </div>
                </TabPane>
                <TabPane tabId={4}>
                  <div className="tab4">
                    <h2 className="tab4head">Just few last details</h2>
                    <h2>To help us find the right plan</h2>
                    <p>Do you have an existing illness or medical history?</p>
                    <div>
                      <div className="radioBox col-lg-12">
                        <input type="radio" />
                        <div className="opt">
                          <p className="opt1">Existing illness</p>
                          <p className="opt2">
                            Blood pressure, Diabetes, Heart conditions, Asthma,
                            Thyroid, Cancer etc.
                          </p>
                        </div>
                      </div>
                      <div className="radioBox col-lg-12">
                        <input type="radio" />
                        <div className="opt">
                          <p className="opt1">Surgical procedure</p>
                          <p className="opt2">
                            Appendix, Gall bladder, C-section etc.
                          </p>
                        </div>
                      </div>
                      <div className="radioBox col-lg-12">
                        <input type="radio" />
                        <div className="opt">
                          <p className="opt1">None of these</p>
                        </div>
                      </div>
                      <button
                        className="tab1Btn"
                        onClick={() => toggle(activeTab + 1)}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId={5}></TabPane>
              </TabContent>
            </div>
          </div>
        </div>

        <BottomContentPage
          insuranceType={"Health Insurance"}
          firstContent={firstContent}
          firstImage={firstImage}
          secondContent={secondContent}
          secondImage={secondImage}
        />
      </>
    </Layout>
  );
};

export default Health;

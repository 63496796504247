import { TabContent, TabPane } from 'reactstrap'
import OwnerDetails from './OwnerDetails'
import VehicleDetails from './VehicleDetails'
import { useEffect, useState } from 'react'
import NomineeDetails from './NomineeDetails'
import {
  getFiancierBankData,
  getVehiclePincode,
  getVehiclePreviousInsurer,
  getVehicleRto
} from '../../services/masterServices'
import { useSelector } from 'react-redux'

export default function Proposal () {
  const [activeTab, setActiveTab] = useState(1)
  const toggle = tab => setActiveTab(tab)
  const [state, setState] = useState([])

  const [rtoData, setRtoData] = useState([])
  const [pincodeData, setPincodeData] = useState([])
  const [cityData, setCityData] = useState([])
  const [financierData, setFinancierData] = useState([])
  const [insurerData, setInsurerData] = useState([])
  const selectedPlan = useSelector(state => state.root.selectedPlan)
  const apiRequestQQ = useSelector(state => state.root.apiRequestQQ)
  useEffect(() => {
    // getStates()
    //   .then(response => {
    //     if (response.state === true) {
    //       let i = 0;
    //       let arr = []
    //       while (i < response.data.length) {
    //         arr.push({
    //           value: response.data[i].Digit_Code,
    //           label: response.data[i].State_Name,
    //         })
    //         i++
    //       }
    //       setState(arr);
    //     }
    //   }).catch(err => console.log(err));
    getVehiclePincode()
      .then(response => {
        if (response.status === true) {
          let i = 0
          let arr = []
          let arr1 = []
          let j = 0
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + ' - ' + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City
            })

            i++
          }
          while (j < response.data.length) {
            arr1.push({
              value: response.data[j].Pin_Code,
              label: response.data[j].District,
              District: response.data[j].District,
              City: response.data[j].City
            })

            j++
          }
          setPincodeData(arr)
          setCityData(arr1)
        }
      })
      .catch(err => console.log(err))

    getFiancierBankData()
      .then(response => {
        if (response.status === true) {
          let i = 0
          let arr = []
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].bank_name,
              label: response.data[i].bank_name,
              HDFCId: response.data[i].HDFCId
            })

            i++
          }
          setFinancierData(arr)
        }
      })
      .catch(err => console.log(err))

    getVehiclePreviousInsurer().then(response => {
      if (response.status === true) {
        let i = 0
        let data = response.data
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            option: item.Name,
            value: item.Digit_Code
          })
          i++
        }
        setInsurerData(arr)
      }
    })
    getVehicleRto().then(response => {
      if (response.status === true) {
        let data = response.data
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.registered_city_name + ' (' + item.RTO_Code + ')',
            value: item.RTO_Code,
            registered_state_name: item.registered_state_name,
            id: item.id
          })
          i++
        }
        setRtoData(arr)
      }
    })
  }, [])

  const getPreviousPolicyName = () => {
    let index = PreviousInsurer.findIndex(
      item => item.Digit_Code == apiRequestQQ.PreInsurerCode
    )
    if (index > -1) {
      return PreviousInsurer[index].Name
    } else {
      return 'N/A'
    }
  }
  // const incrementTab = ()=> setActiveTab(prev=>prev+1)
  return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId={1}>
        <OwnerDetails
          pincodeData={pincodeData}
          state={state}
          activeTab={activeTab}
          toggle={tab => toggle(tab)}
        />
      </TabPane>
      <TabPane tabId={2}>
        <NomineeDetails activeTab={activeTab} toggle={tab => toggle(tab)} />
      </TabPane>
      <TabPane tabId={3}>
        <VehicleDetails
          cityData={cityData.reduce(
            (c, n) => (c.find(el => el.District == n.District) ? c : [...c, n]),
            []
          )}
          insurerData={insurerData}
          financierData={financierData}
          rtoData={rtoData}
          activeTab={activeTab}
          toggle={tab => toggle(tab)}
        />
      </TabPane>
    </TabContent>
  )
}

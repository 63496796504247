import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Claim from "./components/pages/claim/Claim";
import Bike from "./components/pages/InsuraceInfoPages/Bike";
import Car from "./components/pages/InsuraceInfoPages/Car";
// import GCV from "./components/pages/InsuraceInfoPages/GCV";
import Health from "./components/pages/InsuraceInfoPages/Health";
// import PCV from "./components/pages/InsuraceInfoPages/PCV";
// import Renew from "./components/pages/renew/Renew";
// import Support from "./components/pages/support/Support";
// import Aboutus from "./components/pages/support/Aboutus";
import Contact from "./components/pages/support/contactUs/Contact";
// import Privacy from "./components/pages/support/Privacy";
// import Faq from "./components/pages/support/Faq";
// import TnC from "./components/pages/support/TnC";
import MotorProcess from "./components/pages/insuranceProcess/MotorProcess";
// import HealthProcess from "./components/pages/insuranceProcess/HealthProcess";
import MotorQuotation from "./components/pages/insuranceProcess/MotorQuotation";
// import ProposalForm from "./components/pages/Proposal/ProposalForm";
// import PolicyOverview from "./components/pages/Proposal/PolicyOverview";
// import MyDashboard from "./components/pages/User/MyDashboard";
// import QuotationDoc from "./components/common/MotorInsurance/QuotationDoc";
// import HealthQuotation from "./components/pages/insuranceProcess/HealthQuotation";
import Index from "./components/pages/Index";
import AboutUs from "./components/pages/support/aboutUs/AboutUs";
import Taxi from "./components/pages/InsuraceInfoPages/Taxi";
import PosRegistraion from "./components/pages/posRegistration/PosRegistraion";
import Blogs from "./components/pages/blogs/Blogs";
import BlogDetails from "./components/pages/blogs/BlogDetails";
import VehicleDetails from "./components/common/Proposal/VehicleDetails";
import Proposal from "./components/common/Proposal/Proposal";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/bike-insurance" element={<Bike />} />
      <Route path="/car-insurance" element={<Car />} />
      <Route path="/taxi-insurance" element={<Taxi />} />
      {/* <Route path="/gcv-insurance" element={<GCV />} /> */}
      {/* <Route path="/pcv-insurance" element={<PCV />} /> */}
      <Route path="/health-insurance" element={<Health />} />
      {/* <Route path="/renew-policy" element={<Renew />} /> */}
      {/* <Route path="/claim" element={<Claim />} /> */}
      {/* <Route path="/support" element={<Support />} /> */}
      {/* <Route path="/tnc" element={<TnC />} /> */}
      {/* <Route path="/privacy-policy" element={<Privacy />} /> */}
      <Route path="/contact-us" element={<Contact />} />
      {/* <Route path="/faq" element={<Faq />} /> */}
      {/* <Route path="/motor" element={<MotorProcess />} /> */}
      {/* <Route path="/health" element={<HealthProcess />} /> */}
      <Route path="/quotation" element={<MotorQuotation />} />
      <Route path="/pos" element={<PosRegistraion />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blog-details" element={<BlogDetails />} />
      <Route path="/proposal" element={<Proposal />} />
      {/* <Route path="/health-quotation" element={<HealthQuotation />} /> */}
      {/* <Route path="/proposal" element={<ProposalForm />} /> */}
      {/* <Route path="/checkout" element={<PolicyOverview />} /> */}
      {/* <Route path="/dashboard" element={<MyDashboard />} /> */}
      {/* <Route path="/download-quotation" element={<QuotationDoc />} /> */}
    </Routes>
  );
};

export default App;

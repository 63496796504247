import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, TabContent, TabPane } from "reactstrap";
import $ from "jquery";
import { PostDataWithToken } from "../../api/apiHelper";
import { useForm } from "react-hook-form";
import {
  dispatchQuickQuote,
  openLoginPopUp,
  saveUserDetails,
} from "../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  desktopNavlinks,
  homeNavLinks,
  homePageNavigationLinks,
  mobileLinks,
  navLinks,
  navigationLinks,
} from "../services/endpoints";
import {
  sendErrorInfo,
  sendErrorMessage,
  sendSuccessInfo,
  sendSuccessMessage,
} from "../services/masterServices";
import cookie from "react-cookies";
import "../css/header.css";
const Header = () => {
  const location = useLocation();
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const userDetails = useSelector((state) => state.root.userDetails);
  const loginPopup = useSelector((state) => state.root.loginPopup);
  const [modal, setModal] = useState(false);
  const [OTP, setOTP] = useState("");
  const toggleModal = () => setModal(!modal);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const toggleTab = (tab) => setActiveTab(tab);
  const dispatch = useDispatch();
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  function handleInputChange(index, event) {
    const value = event.target.value;
    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });

  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    let header = document.getElementById("onscroll-header");
    let header1 = document.getElementById("header-height");

    window.onscroll = (e) => {
      if (window.scrollY < 95) {
        header != null && header.classList.remove("shadow");
        header1 != null && header1.classList.remove("base-header-hide");
      } else {
        header != null && header.classList.add("shadow");
        header1 != null && header1.classList.add("base-header-hide");
      }
    };
    let headernew = document.getElementById("HomePageHeader");
    window.onscroll = (e) => {
      if (window.scrollY < 95) {
        headernew.classList.remove("scrollheaderbg");
      } else {
        headernew.classList.add("scrollheaderbg");
      }
    };
  }, []);
  useEffect(() => {
    if (loginPopup === true) {
      toggleModal();
    }
    if (modal === false) {
      dispatch(openLoginPopUp(false));
    }
  }, [loginPopup, modal]);

  function myhover() {
    document.getElementById("hover1").classList.add("intro");
    document.getElementById("hover2").classList.remove("intro");
  }
  //

  const submitLogin = (data) => {
    dispatchQuickQuote("MobileNumber", data.MobileNumber);
    PostDataWithToken("auth/customer-login", { phone: data.MobileNumber }).then(
      (response) => {
        if (response.status === true) {
          setOTP(response.data.verification_code.toString());
          toggleTab(activeTab + 1);
        } else {
          sendErrorMessage(response);
        }
      }
    );
  };

  const SubmitOTP = (e) => {
    e.preventDefault();
    if (OTP.length != 4) {
      return sendErrorInfo("Please enter OTP");
    }
    let postData = {
      phone: apiRequestQQ.MobileNumber,
      otp: OTP,
    };
    PostDataWithToken("auth/verify-otp", postData).then((response) => {
      if (response.status === true) {
        cookie.save("user_details", JSON.stringify(response.data), {
          path: "/",
        });
        dispatch(saveUserDetails(response.data));

        cookie.save("token", response.data.access_token);
        reset({
          name: response.data.first_name + " " + response.data.last_name || "",
          dob: response.data.dob,
          email: response.data.email,
          gender: response.data.gender,
          phone: response.data.phone,
        });
        let ReduxData = {
          Email: response.data.email,
          FirstName: response.data.first_name,
          LastName: response.data.last_name,
          MiddleName: "",
          MobileNumber: response.data.phone,
          Gender:
            response.data.gender &&
            response.data.gender.charAt(0).toUpperCase() +
              response.data.gender.slice(1),
          Dob: response.data.dob,
        };
        for (let key in ReduxData) {
          dispatchQuickQuote(key, ReduxData[key]);
        }
        if (
          ReduxData.Email &&
          ReduxData.FirstName &&
          ReduxData.LastName &&
          ReduxData.Email &&
          ReduxData.Dob &&
          ReduxData.Gender
        ) {
          toggleModal();
        } else {
          toggleTab(activeTab + 1);
        }
      } else {
        sendErrorMessage(response);
      }
    });
  };

  const editUserProfile = (data) => {
    let postData = {
      first_name: data.name.split(" ")[0],
      last_name: data.name.split(" ")[1],
      dob: data.dob,
      email: data.email,
      gender: data.gender,
      phone: data.phone,
    };
    PostDataWithToken(`auth/edit-profile/${userDetails?.id}`, postData).then(
      (response) => {
        if (response.status === true) {
          toggleModal();
          let ReduxData = {
            Email: response.data.email,
            FirstName: response.data.first_name,
            LastName: response.data.last_name,
            MiddleName: "",
            MobileNumber: response.data.phone,
            Gender:
              response.data.gender.charAt(0).toUpperCase() +
              response.data.gender.slice(1),
            Dob: response.data.dob,
          };
          for (let key in ReduxData) {
            dispatchQuickQuote(key, ReduxData[key]);
          }

          sendSuccessMessage(response);
        } else {
          sendErrorMessage(response);
        }
      }
    );
  };

  const userLogout = () => {
    cookie.remove("token");
    cookie.remove("user_details");
    dispatch(saveUserDetails({}));
    navigate("/");
  };

  const renderLink = (link) => {
    if (link.dropdownItems) {
      return (
        <li className="navigation__item dropdown" key={link.text}>
          <a
            href={link.link}
            className={link.className}
            data-bs-toggle="dropdown"
          >
            {link.text}
          </a>
          <ul className="dropdown-menu">
            {link.dropdownItems.map((dropdownItem) => (
              <li className="navigation__item" key={dropdownItem.text}>
                <NavLink
                  className={dropdownItem.className}
                  to={dropdownItem.link}
                >
                  {dropdownItem.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </li>
      );
    } else {
      return (
        <li className="navigation__item" key={link.text}>
          <NavLink
            style={link.link === "/" ? { color: "#fff!important" } : {}}
            className={link.className}
            to={link.link}
          >
            {link.text}
          </NavLink>
        </li>
      );
    }
  };
  const renderDesktopLink = (link) => {
    if (link.dropdownItems) {
      return (
        <li className="nav-item dropdown navhoverdrop" key={link.text}>
          <a
            className={link.className}
            href={link.link}
            role="button"
            data-bs-toggle="dropdown"
          >
            {link.text}
          </a>
          <ul className="dropdown-menu">
            {link.dropdownItems.map((dropdownItem) => (
              <li key={dropdownItem.text}>
                <NavLink
                  className={dropdownItem.className}
                  to={dropdownItem.link}
                >
                  {dropdownItem.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </li>
      );
    } else {
      return (
        <li className="nav-item" key={link.text}>
          <NavLink className={link.className} to={link.link}>
            {link.text}
          </NavLink>
        </li>
      );
    }
  };

  return (
    <>
      <header id="HomePageHeader">
        {/*----- navbar start -----*/}
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            {/*----- website logo -----*/}
            <NavLink className="navbar-brand logo" to={"/"}>
              Expert Cover
            </NavLink>
            {/*----- Menu toggle button for small screens -----*/}
            <input
              type="checkbox"
              className="navigation__checkbox d-none"
              id="navi-toggle"
            />
            <label
              htmlFor="navi-toggle"
              className="navigation__button d-lg-none"
            >
              <span className="navigation__icon">&nbsp;</span>
            </label>
            <div className="navigation__background d-lg-none">&nbsp;</div>
            <nav className="navigation__nav d-lg-none">
              <ul className="navigation__list">
                {mobileLinks.map(renderLink)}
              </ul>
            </nav>
            {/*----- navbar links container start -----*/}
            <div
              className="collapse navbar-collapse font-jost"
              id="insurefastnavdrop"
            >
              <ul className="navbar-nav ms-lg-auto">
                {/*----- navlink -----*/}
                {desktopNavlinks.map(renderDesktopLink)}
                {/*----- navlink -----*/}

                <li className="nav-item d-flex flex-column justify-content-center align-items-center position-relative">
                  <a
                    className="btn nav-link text-white fw-light mybutton"
                    data-bs-toggle="modal"
                    href="javascript:void(0)"
                    onClick={() => {
                      toggleModal();
                    }}
                  >
                    Find And Advisor
                  </a>
                  <i className="fa-solid fa-chevron-right" />
                </li>
                <li className="nav-item d-flex flex-column justify-content-center align-items-center position-relative">
                  <a
                    className="btn nav-link text-white fw-light mybutton1"
                    data-bs-toggle="modal"
                    onClick={() => navigate("/pos")}
                  >
                    Become A POS
                  </a>
                  <i className="fa-solid fa-chevron-right" />
                </li>
              </ul>
            </div>
            {/*----- navbar links container end -----*/}
          </div>
        </nav>
        {/*----- navbar end -----*/}
      </header>

      {/* Non scroll header */}

      <Modal
        isOpen={modal}
        backdropClassName="intro"
        modalClassName="loginpopup"
        centered
        size="lg"
        toggle={toggleModal}
      >
        <button
          type="button"
          onClick={toggleModal}
          className="btn-close"
          data-bs-dismiss="modal"
        />

        <ModalBody>
          <TabContent activeTab={activeTab}>
            {/* <TabPane tabId={1}>
                <div className="row align-items-center justify-content-center modal-after">
                  <div className="col-lg-5 col-md-8 col-12">
                    <img src="./assets/img/logpopimg.png" alt="" className="w-100" />
                  </div>

                  <div className="col-lg-6 col-12 ms-auto">
                    <h1 className="font-jost display-5 fw-400 mb-md-5 mb-3 mt-4 mt-lg-0 pb-3">
                      Start with expertcover as?
                    </h1>
                    <button
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                      id="hover1"
                      onMouseOver={myhover}
                      className="customerloginbutton w-100 first intro"
                    >
                      Customer
                    </button>

                    <br />

                    <button
                      onClick={() => {
                        toggleTab(activeTab + 1);
                      }}
                      id="hover2"
                      onMouseOver={myhover2}
                      className="customerloginbutton w-100 second"
                    >
                      Employee
                    </button>
                  </div>
                </div>
              </TabPane> */}

            <TabPane tabId={1}>
              <div className="row align-items-center">
                <div className="col-lg-10 col-12 mx-auto">
                  <h1 className="font-jost display-5 fw-400 mb-5 pb-3">
                    Login with Mobile Number
                  </h1>
                </div>

                <div className="col-lg-10 col-12 mx-auto">
                  <form onSubmit={handleSubmit1(submitLogin)}>
                    <div className="mb-3">
                      <input
                        type="number"
                        className="form-control"
                        {...register1("MobileNumber", {
                          required: "Mobile Number is required",
                          pattern: {
                            value:
                              /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                            message: "Invalid Mobile Number",
                          },
                        })}
                        placeholder="Enter your 10 digit mobile number"
                      />
                      <p className="text-danger">
                        {errors1?.MobileNumber?.message}
                      </p>
                    </div>

                    <div className="mb-3 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary fs-3 px-5 py-3"
                      >
                        Get OTP
                      </button>

                      <small className="text-muted d-block mt-5">
                        By continuing, I agree to
                        <Link
                          to="/tnc"
                          className="text-dark fw-600 text-decoration-none ms-1"
                        >
                          Terms &amp; Conditions
                        </Link>
                      </small>
                    </div>
                  </form>
                </div>
              </div>
            </TabPane>

            <TabPane tabId={2}>
              <div className="row align-items-center">
                <div className="col-lg-9 col-12 mx-auto">
                  <h1 className="font-jost display-6 fw-400 mb-5 pb-3 ps-4">
                    Enter the OTP send on
                    <span className="text-decoration-underline ms-2 me-3">
                      {apiRequestQQ.MobileNumber}
                    </span>
                    <a
                      href="javascript:void(0)"
                      onClick={() => toggleTab(activeTab + 1)}
                      className="text-decoration-none fs-4"
                    >
                      Change
                    </a>
                  </h1>
                </div>

                <div className="col-lg-10 col-12 mx-auto">
                  <form onSubmit={SubmitOTP}>
                    <div className="mb-3 otpinputs d-flex justify-content-evenly align-items-center">
                      {Array.from({ length: 4 }, (_, index) => (
                        <input
                          type="number"
                          className="form-control"
                          placeholder=" "
                          maxLength={1}
                          key={index}
                          ref={inputRefs[index]}
                          value={OTP[index] || ""}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                      ))}
                    </div>

                    <div className="mb-3 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary fs-3 px-5 py-3"
                      >
                        Submit OTP
                      </button>

                      <small className="text-muted d-block mt-5">
                        Didn’t get the OTP?
                        <a
                          href="#!"
                          className="text-primary text-decoration-none fw-600 ms-2"
                        >
                          Resend
                        </a>
                      </small>
                    </div>
                  </form>
                </div>
              </div>
            </TabPane>
            <TabPane tabId={3}>
              <div className="row align-items-center">
                <div className="col-lg-10 col-12 mx-auto">
                  <h1 className="font-jost display-5 fw-400 mb-5">
                    My Profile
                  </h1>
                </div>
                <div className="col-lg-10 col-12 mx-auto yesnoinput">
                  <form
                    onSubmit={handleSubmit(editUserProfile)}
                    className="row mx-0 px-0"
                  >
                    <div className="col-lg-12 col-12 px-lg-0 mb-4">
                      <input
                        type="text"
                        className="form-control mb-0"
                        placeholder="Enter your name"
                        {...register("name", {
                          required: "Name is required",
                        })}
                      />
                      {errors.name && (
                        <p className="text-danger"> {errors.name.message} </p>
                      )}
                    </div>
                    <div className="col-lg-6 col-12 ps-lg-0 mb-4">
                      <input
                        type="radio"
                        className="d-none"
                        id="male"
                        name="gender"
                        value="male"
                        {...register("gender", {
                          required: "Gender is required",
                        })}
                      />
                      <label htmlFor="male" className="w-100 m-0">
                        Male
                      </label>
                    </div>
                    <div className="col-lg-6 col-12 pe-lg-0 mb-4">
                      <input
                        type="radio"
                        className="d-none"
                        id="female"
                        name="gender"
                        value="female"
                        {...register("gender", {
                          required: "Gender is required",
                        })}
                      />
                      <label htmlFor="female" className="w-100 m-0">
                        Female
                      </label>
                    </div>
                    <div className="col-lg-12">
                      {errors.gender && (
                        <p className="text-danger"> {errors.gender.message} </p>
                      )}
                    </div>
                    <div className="col-lg-12 col-12 px-lg-0 mb-4">
                      <input
                        type="date"
                        data-toggle="datepicker"
                        className="form-control mb-0"
                        placeholder="Enter your DOB"
                        {...register("dob", {
                          required: "Dob is required",
                        })}
                      />
                    </div>
                    {errors.dob && (
                      <p className="text-danger"> {errors.dob.message} </p>
                    )}
                    <div className="col-lg-12 col-12 px-lg-0 mb-4">
                      <input
                        type="text"
                        className="form-control mb-0"
                        placeholder="Enter your mobile number"
                        disabled
                        {...register("phone", {
                          required: true,
                          pattern: {
                            value:
                              /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                            message: "Invalid mobile number",
                          },
                        })}
                      />

                      {errors.mobile && (
                        <p className="text-danger">{errors.phone.message}</p>
                      )}
                    </div>
                    <div className="col-lg-12 col-12 px-lg-0">
                      <input
                        type="email"
                        className="form-control mb-0"
                        placeholder="Enter your mail address"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="text-danger">{errors.email.message}</p>
                      )}
                    </div>
                    <div className="mt-5 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary fs-3 px-5 py-3 mt-3"
                      >
                        Save Details
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Header;

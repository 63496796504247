import React from "react";
import Layout from "../../common/Layout";
import { TabContent, TabPane } from "reactstrap";
import "./car.css";
import BottomContentPage from "../../common/BottomContentPage/BottomContentPage";
import FormTabs from "../../common/FormTabs/FormTabs";

const Car = () => {
  const [activeTab, setActiveTab] = React.useState(1);
  const toggle = (tab) => setActiveTab(tab);
  const firstImage = "./assets/img/Frame (6).png";
  const secondImage = "./assets/img/bnft.jpg";
  const firstContent = [
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock",
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary",
    "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum",
    "If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. There is no minimum donation, any sum is appreciated",
  ];
  const secondContent = [
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock",
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary",
    "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum",
    "If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. There is no minimum donation, any sum is appreciated",
  ];

  return (
    <Layout pageClass={"carpage"}>
      <section>
        <div className="img-and-form">
          <img
            src="./assets/img/modern_car_032_7850 1.jpg"
            alt="failed-to-load"
            width={"50%"}
            height={"50%"}
          />
          <FormTabs vehicleType={"4w"} />{" "}
        </div>
      </section>
      <BottomContentPage
        insuranceType={"Car Insurance"}
        firstContent={firstContent}
        firstImage={firstImage}
        secondContent={secondContent}
        secondImage={secondImage}
      />
    </Layout>
  );
};

export default Car;

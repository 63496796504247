import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { GetDataWithToken } from '../../api/apiHelper'
import { createQuickQuoteSet } from '../../store/actions/userActions'
import Layout from '../common/Layout'
import { createPolicyObject } from '../utility/helperFunctions'
import { getVehiclePreviousInsurer, getVehicleRto } from '../services/masterServices'

const Index = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.root.userDetails)
  const [recentPolicies, setRecentPolicies] = useState([])
  const [vehicleModel, setVehicleModel] = React.useState('')
  const [quickPick, setQuickPick] = React.useState([])
  const [activeTab, setActiveTab] = React.useState(1)
  const [makeData, setMakeData] = React.useState([])
  const [modelData, setModelData] = React.useState([])
  const [variantData, setVariantData] = React.useState([])
  const [insurerData, setInsurerData] = React.useState([])
  const [rtoData, setRtoData] = React.useState([])
  const [RegistrationNumber, setRegistrationNumner] = useState('')
  const [isNewVehicle, setIsNewVehicle] = useState(false)
  const [regYear, setRegYear] = useState('')
  const [policyInputs, setPolicyInputs] = React.useState([])
  const [fuelTypes, setFuelTypes] = useState([])

  // useEffect(() => {
  //   GetDataWithToken(`motor/recent-policies/${userDetails.id}`, '').then(
  //     response => {
  //       if (response.status === true) {
  //         setRecentPolicies(response.data)
  //       }
  //     }
  //   )
  // }, [userDetails?.id])

  // const selectSearch = item => {
  //   dispatch(createQuickQuoteSet(createPolicyObject(item)))
  //   navigate('/quotation')
  // }

  useEffect(() => {
    getVehicleRto().then(response => {
      console.log(response, 'response')
      if (response.status === true) {
        let data = response?.data
        let i = 0
        let arr = []
        while (i < data?.length) {
          let item = data[i]
          arr.push({
            label: item.registered_city_name + ' (' + item.RTO_Code + ')',
            value: item.RTO_Code,
            registered_state_name: item.registered_state_name,
            id: item.id,
            registered_city_name: item.registered_city_name
          })
          i++
        }
        setRtoData(arr)
      }
    })
    getVehiclePreviousInsurer().then(response => {
      if (response.status === true) {
        let i = 0
        let data = response.data
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            option: item.Name,
            value: item.Digit_Code
          })
          i++
        }
        setInsurerData(arr)
      }
    })
  }, [])

  const ProductCards = ({ image, product }) => {
    return (
      <>
        <div className='card text-center h-100 py-3'>
          <div className='card-body py-4'>
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                product == 'Health'
                  ? navigate('/health-insurance')
                  : product == 'Bike'
                  ? navigate('/bike-insurance')
                  : product == 'PVT car'
                  ? navigate('/car-insurance')
                  : product == 'Taxi'
                  ? navigate('/taxi-insurance')
                  : ''
              }}
              className='stretched-link  d-block text-dark text-decoration-none'
            >
              <img src={image} alt='' />
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <span>
                  <p className='mb-0 mt-3 pt-2 fw-bold'>{product}</p>
                  <p className='mb-0'>Insurance</p>
                </span>
                <input type='radio' className='radio_btn' />
              </span>
            </a>
          </div>
        </div>
      </>
    )
  }

  return (
    <Layout pageClass={'homepage'}>
      <section className='herobanner my-0 pb-0'>
        <div className='container'>
          {/*----- Banner part -----*/}
          <div className='row align-items-center justify-content-around'>
            <div className='col-lg-6 col-12'>
              <p className='bannerheading text-dark mb-0'>
                The Futuristic Way To
                <br className='d-lg-block d-none' />
                <span>Buy Insurance</span>
              </p>
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
                className='col-lg-9'
              >
                <span
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <img
                    src='./assets/img/clock.png'
                    width='25px'
                    height='25px'
                  />
                  <p className='p_text'>Renew Policy</p>
                </span>
                <span
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <img
                    src='./assets/img/locate.png'
                    width='25px'
                    height='25px'
                  />
                  <p className='p_text'>Track Policy</p>
                </span>
                <span
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <img
                    src='./assets/img/download.png'
                    width='25px'
                    height='25px'
                  />
                  <p className='p_text'>Download Policy</p>
                </span>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <img
                src='./assets/img/main-hero-img.png'
                className='w-100'
                alt=''
              />
            </div>
          </div>
          <div className='Insurance_card_container' id='MYICARD'>
            <div className='container'>
              {/*----- Insurance card part -----*/}
              <div className='d-flex flex-wrap align-items-center justify-content-lg-evenly InscardContainer'>
                <ProductCards
                  image={process.env.PUBLIC_URL + '/assets/img/pvt_car.png'}
                  product='PVT car'
                />
                <ProductCards
                  image={process.env.PUBLIC_URL + '/assets/img/bike.png'}
                  product='Bike'
                />
                <ProductCards
                  image={process.env.PUBLIC_URL + '/assets/img/taxi.png'}
                  product='Taxi'
                />
                <ProductCards
                  image={process.env.PUBLIC_URL + '/assets/img/gcv.png'}
                  product='GCV'
                />
                <ProductCards
                  image={process.env.PUBLIC_URL + '/assets/img/pa.png'}
                  product='PA'
                />
                <ProductCards
                  image={process.env.PUBLIC_URL + '/assets/img/health.png'}
                  product='Health'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*----- Hero banner end -----*/}

      <section className='currentvisitscards my-0 py-0'>
        {userDetails?.id && recentPolicies && recentPolicies.length > 0 && (
          <Slider
            {...settings3}
            slidesToShow={Math.min(
              recentPolicies.length,
              settings3.slidesToShow
            )}
          >
            {recentPolicies.map((item, i) => (
              <a
                href='#!'
                className='d-block card text-decoration-none'
                key={i}
                style={{ cursor: 'pointer' }}
                onClick={() => selectSearch(item)}
              >
                <div className='card-body'>
                  <img
                    src={
                      item?.customer?.motorInsurance?.vehicle_type ==
                      'MotorBike'
                        ? './assets/img/bikeicon.png'
                        : item?.customer?.motorInsurance?.vehicle_type ==
                          'Pvt Car'
                        ? './assets/img/caricon.png'
                        : item?.customer?.motorInsurance?.vehicle_type == 'pcv'
                        ? './assets/img/pcvicon.png'
                        : './assets/img/gcvicon.png'
                    }
                    alt=''
                  />

                  <h4 className='text-dark fw-bold mb-0'>
                    {item?.customer?.motorInsurance?.vehicle_no
                      ? item?.customer?.motorInsurance?.vehicle_no
                      : item?.customer?.motorInsurance?.rto}
                  </h4>

                  <p className='text-dark mb-1'>
                    {item?.customer?.motorInsurance?.vehicle_make} |{' '}
                    {item?.customer?.motorInsurance?.vehicle_model} |{' '}
                    {item?.customer?.motorInsurance?.vehicle_variant} (
                    {item?.customer?.motorInsurance?.vehicle_mfg_yr})
                  </p>

                  <p className='text-dark'>
                    ({item?.customer?.motorInsurance?.vehicle_type})
                  </p>
                </div>{' '}
              </a>
            ))}
          </Slider>
        )}
      </section>

      {/*----- expertcover about section start -----*/}
      <section className='about'>
        <div className='container px-lg-2 px-3'>
          <div className='row justify-content-between mt-5'>
            <div className='col-12'>
              {/*----- section heading -----*/}
              <div className='sectionheading mb-5 pb-3'>
                <h1 className='text-center'>
                  Why Choose <span className='boldheading'>Expert Cover</span>
                </h1>
              </div>
            </div>
            <div className='col-lg-3 col-12 mb-lg-0 mb-4'>
              {/*----- About cards -----*/}
              <div className='card  border-0 h-100'>
                <div className='card-body'>
                  <img src='./assets/img/about_img_1.png' alt='' />
                  <p className='font-jost mb-3 fw-bold fs-2'>Over 9 million</p>
                  <p className='mb-0 fs-4 text-secondary'>
                    It was popularised in the 1960s with the release of Letraset
                    sheets containing Lorem Ipsum passages
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-12 mb-lg-0 mb-4'>
              {/*----- About cards -----*/}
              <div className='card  border-0 h-100'>
                <div className='card-body'>
                  <img src='./assets/img/about_img_2.png' alt='' />
                  <p className='font-jost mb-3 fw-bold fs-2'>50+ insurers</p>
                  <p className='mb-0 fs-4 text-secondary'>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-12 mb-lg-0 mb-4'>
              {/*----- About cards -----*/}
              <div className='card  border-0 h-100'>
                <div className='card-body'>
                  <img src='./assets/img/about_img_3.png' alt='' />
                  <p className='font-jost mb-3 fw-bold fs-2'>Great Price</p>
                  <p className='mb-0 fs-4 text-secondary'>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered
                  </p>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-12 mb-lg-0 mb-4'>
              {/*----- About cards -----*/}
              <div className='card  border-0 h-100'>
                <div className='card-body'>
                  <img src='./assets/img/about_img_4.png' alt='' />
                  <p className='font-jost mb-3 fw-bold fs-2'>Claims</p>
                  <p className='mb-0 fs-4 text-secondary'>
                    The standard chunk of Lorem Ipsum used since the 1500s is
                    reproduced below for those interested.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*----- expertcover about section end -----*/}

      {/*----- Expert Cover Service content sction start -----*/}

      <section className='service'>
        <div className='service_1'>
          <img src='./assets/img/ser_1_img_1.png' width='60px' />
          <p className='ser_1_text_1'>Health Insurance</p>
          <p className='ser_1_text_2'>
            Protect Yourself &amp; <br />
            your Family against
          </p>
          <h3>COVID-19</h3>
          <img src='./assets/img/ser_1_img_2.png' width='200px' id='img2' />
        </div>

        <div className='service_2'>
          <img src='./assets/img/ser_2_img_1.png' width='60px' />
          <p className='ser_2_text_1'>Term Insurance</p>
          <p className='ser_2_text_2'>Secure your</p>
          <h3>Family’s</h3>
          <p className='ser_2_text_2'>Financial Future</p>
          <img src='./assets/img/ser_2_img_2.png' width='168px' id='img2' />
        </div>

        <div className='service_3'>
          <img src='./assets/img/ser_3_img_1.png' width='60px' />
          <p className='ser_3_text_1'>Home Insurance</p>
          <p className='ser_3_text_2'>
            50Lakhs Cover for Your
            <br /> Home Insurance Starting at{' '}
          </p>
          <h3>₹80/month* </h3>
          <img src='./assets/img/ser_3_img_2.png' width='250px' id='img2' />
        </div>

        <div className='service_4'>
          <img src='./assets/img/ser_4_img_1.png' width='60px' />
          <p className='ser_4_text_1'>Business Insurance</p>
          <p className='ser_4_text_2'>One stop for all your</p>
          <h3>Business</h3>
          <p className='ser_4_text_2'>Insurance Need</p>
          <img src='./assets/img/ser_4_img_2.png' width='90px' id='img2' />
          <img src='./assets/img/ser_4_img_3.png' width='100px' id='img3' />
        </div>
      </section>
      {/*----- Expert Cover Service content sction end -----*/}

      {/* Expert Cover Advantages Section Start */}
      <section className='advantages'>
        <h1>Expert Cover Advantages</h1>
        <p>
          When you buy insurance from us, you get more than just financial
          safety. You also get: our promise of simplifying
          <br /> complex insurance terms and conditions, quick stress-free
          claims, instant quotes from top insurers and being
          <br /> present for you in the toughest of times.
        </p>
        <div className='cards'>
          <div className='card_1'>
            <img src='./assets/img/card_1_img.png' />
            <h5>
              One of the Best
              <br /> Prices
            </h5>
            <p>Guaranteed</p>
          </div>
          <div className='card_2'>
            <img src='./assets/img/card_2_img.png' />
            <h5>Unbiased Advice</h5>
            <p>Keeping customers first</p>
          </div>
          <div className='card_3'>
            <img src='./assets/img/card_3_img.png' />
            <h5>100% Reliable</h5>
            <p>Regulated by IRDAI</p>
          </div>
          <div className='card_4'>
            <img src='./assets/img/card_4_img.png' />
            <h5>Claims Support</h5>
            <p>Made stress-free</p>
          </div>
          <div className='card_5'>
            <img src='./assets/img/card_5_img.png' />
            <h5>Happy to Help</h5>
            <p>Every day of the week</p>
          </div>
        </div>
      </section>
      {/* Expert Cover Advantages Section End */}

      {/* Our Partners section start */}

      <section className='partners'>
        <h1>Our Partners</h1>
        <div className='image_container'>
          <div>
            <img src='./assets/img/digitpartner.png' id='img1' />
          </div>
          <div>
            <img src='./assets/img/bajajpartner.png' id='img2' />
          </div>
          <div>
            <img src='./assets/img/kotakpartner.png' id='img3' />
          </div>
          <div>
            <img src='./assets/img/shrirampartner.png' id='img4' />
          </div>
          <div>
            <img src='./assets/img/sbipartner.png' id='img5' />
          </div>
        </div>
      </section>

      {/* Our Partners section End */}

      {/* Testimonials section start */}

      <section className='testimonials'>
        <h1>Our Customer Love Us</h1>
        <div className='container'>
          <div>
            <img src='./assets/img/tstmlImg.png' />
            <p className='description'>
              Contrary to popular belief, Lorem Ipsum is <br />
              not simply random text. It has roots in a <br />
              piece of classical Latin literature from 45
              <br /> BC, making it over 2000 years old. Richard
              <br /> McClintock, a Latin professor at Hampden
              <br />
              -Sydney College in Virginia, looked up one <br />
              of the more obscure Latin words,
              <br /> consectetur, from a Lorem Ipsum passage
            </p>
            <h4>Saurbh Joshi</h4>
            <p className='location'>Delhi</p>
          </div>
          <div>
            <img src='./assets/img/tstmlImg.png' />
            <p className='description'>
              Contrary to popular belief, Lorem Ipsum is <br />
              not simply random text. It has roots in a <br />
              piece of classical Latin literature from 45
              <br /> BC, making it over 2000 years old. Richard
              <br /> McClintock, a Latin professor at Hampden
              <br />
              -Sydney College in Virginia, looked up one <br />
              of the more obscure Latin words,
              <br /> consectetur, from a Lorem Ipsum passage
            </p>
            <h4>Shivraj Singh</h4>
            <p className='location'>Jaipur, Rajasthan</p>
          </div>
          <div>
            <img src='./assets/img/tstmlImg.png' />
            <p className='description'>
              Contrary to popular belief, Lorem Ipsum is <br />
              not simply random text. It has roots in a <br />
              piece of classical Latin literature from 45
              <br /> BC, making it over 2000 years old. Richard
              <br /> McClintock, a Latin professor at Hampden
              <br />
              -Sydney College in Virginia, looked up one <br />
              of the more obscure Latin words,
              <br /> consectetur, from a Lorem Ipsum passage
            </p>
            <h4>Vikram</h4>
            <p className='location'>Bikaner, Rajasthan</p>
          </div>
        </div>
      </section>

      {/* Testimonials section end */}
    </Layout>
  )
}

export default Index
var settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: false,
  // speed: 2000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}
var settings1 = {
  dots: false,
  infinite: true,
  autoplay: false,
  // speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}
var settings2 = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  // speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}
var settings3 = {
  dots: false,
  infinite: true,
  autoplay: true,
  // speed: 2000,
  centerMode: true,
  centerPadding: '20px',
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

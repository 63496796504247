export const posEndPoint = {
  becomePos: 'auth/become-a-pos',
  registerPos: 'auth/update-pos',
  posLogin: 'auth/trainee-login',
  trainingModule: 'exam/get-module',
  moduleComplete: 'exam/module-complete',
  startExam: 'exam/start-exam',
  postAnwer: 'exam/answer',
  endExam: 'exam/end-exam',
  examResult: 'exam/result',
  changePassword: 'auth/change-password',
  getStates: 'admin/states',
  getDistricts: 'admin/district',
  getBranches: 'admin/get-branch'
}

export const mastersEndPoint = {
  make: 'motor/make',
  model: 'motor/model',
  variant: 'motor/variant',
  rto: 'motor/rto',
  previousInsurer: 'motor/previous-insurer',
  pincode: 'motor/pincode',
  state: 'motor/states',
  bajajMMV: 'motor/getBajajMMV',
  hdfcChecksum: 'motor/hdfcChecksum',
  getFiancierBanks: 'motor/getFiancierBanks'
}
export const mobileLinks = [
  {
    text: 'Insurance Products',
    link: '#',
    className: 'navigation__link dropdown-toggle',
    dropdownItems: [
      {
        text: 'Two Wheeler Insurance',
        link: '/bike-insurance',
        className: 'navigation__link'
      },
      {
        text: 'Four Wheeler Insurance',
        link: '/car-insurance',
        className: 'navigation__link'
      },
      {
        text: 'GCV Insurance',
        link: '/gcv-insurance',
        className: 'navigation__link'
      },
      {
        text: 'PCV Insurance',
        link: '/pcv-insurance',
        className: 'navigation__link'
      },
      {
        text: 'Health Insurance',
        link: '/health-insurance',
        className: 'navigation__link'
      }
    ]
  },
  {
    text: 'Raise a Claim',
    link: '/claim',
    className: 'navigation__link'
  },
  {
    text: 'Support',
    link: '#',
    className: 'navigation__link dropdown-toggle',
    dropdownItems: [
      {
        text: 'About Us',
        link: '/about-us',
        className: 'navigation__link'
      },
      {
        text: 'Contact Us',
        link: '/contact-us',
        className: 'navigation__link'
      }
    ]
  }
]

export const desktopNavlinks = [
  {
    text: 'Insurance Products',
    link: '#!',
    className: 'nav-link dropdown-toggle text-capitalize',
    dropdownItems: [
      {
        text: 'Two Wheeler Insurance',
        link: '/bike-insurance',
        className: 'dropdown-item text-capitalize'
      },
      {
        text: 'Four Wheeler Insurance',
        link: '/car-insurance',
        className: 'dropdown-item text-capitalize'
      },
      {
        text: 'GCV Insurance',
        link: '/gcv-insurance',
        className: 'dropdown-item text-capitalize'
      },
      {
        text: 'PCV Insurance',
        link: '/pcv-insurance',
        className: 'dropdown-item text-capitalize'
      },
      {
        text: 'Health Insurance',
        link: '/health-insurance',
        className: 'dropdown-item text-capitalize'
      }
    ]
  },
  {
    text: 'Raise A Claim',
    link: '/claim',
    className: 'nav-link text-capitalize'
  },
  {
    text: 'Support',
    link: '#',
    className: 'nav-link dropdown-toggle text-capitalize',
    dropdownItems: [
      {
        text: 'About Us',
        link: '/about-us',
        className: 'dropdown-item text-capitalize'
      },
      {
        text: 'Contact Us',
        link: '/contact-us',
        className: 'dropdown-item text-capitalize'
      }
    ]
  }
]

export const navigationLinks = [
  {
    name: 'Insurance Products',
    className: 'navigation__link dropdown-toggle',
    dropdown: true,
    dropdownItems: [
      {
        name: 'Two Wheeler Insurance',
        path: '/bike-insurance',
        className: 'navigation__link',
        activeClassName: 'active'
      },
      {
        name: 'Four Wheeler Insurance',
        path: '/car-insurance',
        className: 'navigation__link',
        activeClassName: 'active'
      },
      {
        name: 'GCV Insurance',
        path: '/gcv-insurance',
        className: 'navigation__link',
        activeClassName: 'active'
      },
      {
        name: 'PCV Insurance',
        path: '/pcv-insurance',
        className: 'navigation__link',
        activeClassName: 'active'
      },
      {
        name: 'Health Insurance',
        path: '/health-insurance',
        className: 'navigation__link',
        activeClassName: 'active'
      }
    ]
  },
  {
    name: 'Raise A Claim',
    path: '/claim',
    className: 'navigation__link',
    activeClassName: 'active'
  },
  {
    name: 'Support',
    className: 'navigation__link dropdown-toggle',
    dropdown: true,
    dropdownItems: [
      {
        name: 'About Us',
        path: '/about-us',
        className: 'navigation__link',
        activeClassName: 'active'
      },
      {
        name: 'Contact Us',
        path: '/contact-us',
        className: 'navigation__link',
        activeClassName: 'active'
      }
    ]
  }
]

export const navLinks = [
  {
    label: 'Insurance Products',
    className: 'nav-link dropdown-toggle text-capitalize',
    dropdown: [
      {
        label: 'Two Wheeler Insurance',
        path: '/bike-insurance',
        className: 'dropdown-item text-capitalize'
      },
      {
        label: 'Four Wheeler Insurance',
        path: '/car-insurance',
        className: 'dropdown-item text-capitalize'
      },
      {
        label: 'GCV Insurance',
        path: '/gcv-insurance',
        className: 'dropdown-item text-capitalize'
      },
      {
        label: 'PCV Insurance',
        path: '/pcv-insurance',
        className: 'dropdown-item text-capitalize'
      },
      {
        label: 'Health Insurance',
        path: '/health-insurance',
        className: 'dropdown-item text-capitalize'
      }
    ]
  },

  {
    label: 'Raise A Claim',
    path: '/claim',
    className: 'nav-link text-capitalize'
  },
  {
    label: 'Support',
    className: 'nav-link dropdown-toggle text-capitalize',
    dropdown: [
      {
        label: 'About Us',
        path: '/about-us',
        className: 'dropdown-item text-capitalize'
      },
      {
        label: 'Contact Us',
        path: '/contact-us',
        className: 'dropdown-item text-capitalize'
      }
    ]
  }
]

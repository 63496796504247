export const LOGGED_USER_DETAILS = "LOGGED_USER_DETAILS";
export const QUICK_QUOTE_PAYLOAD = "QUICK_QUOTE_PAYLOAD";
export const QUICK_QUOTE_RESULTS = "QUICK_QUOTE_RESULTS";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const OPEN_LOGIN_POPUP = "OPEN_LOGIN_POPUP";
export const CREATE_QUICK_QUOTE = "CREATE_QUICK_QUOTE";

export const RESET_QUICK_QUOTE_PAYLOAD = "RESET_QUICK_QUOTE_PAYLOAD";
export const RESET_QUICK_QUOTE_RESULTS = "RESET_QUICK_QUOTE_RESULTS";
export const RESET_SELECTED_PLAN = "RESET_SELECTED_PLAN";

import React, { useState } from "react";
import "./posRegistration.css";
import { Modal, ModalBody, TabContent, TabPane } from "reactstrap";
import Header from "../../common/Header";
import Layout from "../../common/Layout";
import SubHeader from "../../common/subHeader/SubHeader";

function PosRegistraion() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => setIsModalVisible((prev) => !prev);
  const [tab, setTab] = useState(1);
  const nextTab = () => setTab((prev) => prev + 1);
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 50,
          alignItems: "center",
        }}
      >
        <SubHeader headline={"Become a POS"} subHeadline={"Become POS"} />
        <p className="headline" style={{ marginTop: 20 }}>
          POS Registration
        </p>
        <TabContent activeTab={tab}>
          <TabPane tabId={1}>
            <div className="input-container">
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Full name"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Date of birth"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Mobile No."
              />
              <div style={{ flex: "30%", maxWidth: 340, margin: 10 }}>
                <p className="colored-txt">Select Gender</p>
                <div className="pos-linear-div">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="radio" /> <label>Male</label>
                  </div>
                  <div
                    style={{
                      marginLeft: 40,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input type="radio" /> <label>Female</label>
                  </div>
                </div>
              </div>
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Alernate No."
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Residence No."
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Email Id"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Pan Card No."
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Adhar No."
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Select Qualification"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Life Insurance"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Employee Code"
              />
              <p className="note-text" style={{ marginTop: 15 }}>
                <strong style={{ textTransform: "uppercase" }}>Note</strong> :
                Please enter POS certificate number or employee code for
                referral. If you are coming without referral, please enter
                “New”.
              </p>
            </div>
            <div className="btn-wrapper">
              <button className="styled-btn" onClick={toggleModal}>
                Next
              </button>
            </div>
          </TabPane>

          <TabPane tabId={2}>
            <div className="input-container">
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Address 1"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Address 2"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Address 3"
              />

              <input
                type="text"
                className="pos-styled-input"
                placeholder="Date Of Birth"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Select State"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Select City"
              />

              <input
                type="text"
                className="pos-styled-input"
                placeholder="Pin Code"
              />
            </div>
            <div className="btn-wrapper">
              <button
                className="styled-btn"
                style={{ margin: 10 }}
                onClick={toggleModal}
              >
                Previous
              </button>
              <button
                className="styled-btn"
                style={{ margin: 10 }}
                onClick={nextTab}
              >
                Next
              </button>
            </div>
          </TabPane>

          <TabPane tabId={3}>
            <div className="input-container">
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Bank Name"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Bank Branch"
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="Select Account"
              />

              <input
                type="text"
                className="pos-styled-input"
                placeholder="Bank A/C No."
              />
              <input
                type="text"
                className="pos-styled-input"
                placeholder="IFSC Code"
              />
            </div>
            <div className="btn-wrapper">
              <button
                className="styled-btn"
                style={{ margin: 10 }}
                onClick={toggleModal}
              >
                Previous
              </button>
              <button
                className="styled-btn"
                style={{ margin: 10 }}
                onClick={nextTab}
              >
                Next
              </button>
            </div>
          </TabPane>

          <TabPane tabId={4}>
            <div
              className="input-container"
              style={{ maxWidth: 795, alignSelf: "center" }}
            >
              <div className="document-card">
                <img src="./assets/img/pancard-img.png" />
                <p className="document-btn">Pan Card</p>
              </div>
              <div className="document-card">
                <img src="./assets/img/aadhaar_Logo.png" />
                <p className="document-btn">Aadhaar Card</p>
                <strong>(Front)</strong>
              </div>
              <div className="document-card">
                <img src="./assets/img/aadhaar_Logo.png" />
                <p className="document-btn">Aadhaar Card</p>
                <strong>(Back)</strong>
              </div>
              <div className="document-card">
                <img src="./assets/img/certificate.png" />
                <p className="document-btn">Qualification</p>
              </div>
              <div className="document-card">
                <img src="./assets/img/cheque.png" />
                <p className="document-btn">Cheque</p>
              </div>
              <div className="document-card">
                <img src="./assets/img/profile-pic.png" />
                <p className="document-btn">Profile Photo</p>
              </div>

              <p className="note-text" style={{ marginLeft: 40 }}>
                <strong style={{ textTransform: "uppercase" }}>Note</strong> :
                If you don't have documents, don't worry you can skip this step
                by clicking on submit button and you can add the details later.
              </p>
              <div className="declaration">
                <input type="checkbox" />
                <span className="declaration-txt">
                  I hereby declare that the above information is true and
                  correct
                </span>
              </div>
            </div>
            <div className="btn-wrapper">
              <button className="styled-btn" style={{ margin: 10 }}>
                Submit
              </button>
            </div>
          </TabPane>
        </TabContent>
        <Modal
          backdrop={true}
          isOpen={isModalVisible}
          modalClassName="loginpopup"
        >
          <ModalBody>
            <div>
              <button
                onClick={toggleModal}
                style={{
                  float: "right",
                  border: "none",
                  backgroundColor: "white",
                  fontSize: 25,
                  color: "gray",
                }}
              >
                X
              </button>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="headline">OTP Verification</p>
              <p className="modal-sub-headline">
                An OTP has been sent to 6305457747
              </p>
              <img src="./assets/img/otp-verification-img.png" />
              <p>Please Enter the OTP below to verify Phone No.</p>
              <input className="pos-styled-input" />
              <button
                className="styled-btn"
                onClick={() => {
                  nextTab();
                  toggleModal();
                }}
              >
                Submit
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </Layout>
  );
}

export default PosRegistraion;

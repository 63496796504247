import React from "react";
import Layout from "../../../common/Layout";
import "./contact.css";
import SubHeader from "../../../common/subHeader/SubHeader";

const Contact = () => {
  return (
    <Layout pageClass={"contactpage"}>
      <SubHeader headline={"Contact Us"} subHeadline={"Contact Us"} />

      <div>
        <section className="contactSection">
          <div className="contactCard col-lg-12">
            <div className="contact-wrapper">
              <div className="col-lg-6 card1">
                <h1>Contact Info</h1>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary,
                </p>
                <div className="contactInfo">
                  <div className="address col-lg-6">
                    <img src="./assets/img/locationAddress.png" />
                    <div>
                      <h1>Address</h1>
                      <p>
                        Office No.407, Plot No.12, Shiv Marg, Kriti Nagar, Shyam
                        Nagar, Jaipur, Rahasthan 302019
                      </p>
                    </div>
                  </div>
                  <div className="address">
                    <img src="./assets/img/mobileAddress.png" />
                    <div>
                      <h1>Mobile</h1>
                      <p>+91 94900 36767</p>
                    </div>
                  </div>
                  <div className="address">
                    <img src="./assets/img/emailAddress.png" />
                    <div>
                      <h1>Email</h1>
                      <p>Support@Expertcover.com</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 contactForm">
                <h1>Get in Touch</h1>
                <input type="text" placeholder="Name" className="col-lg-12" />
                <input type="text" placeholder="Email" className="col-lg-12" />
                <input type="text" placeholder="Mobile" className="col-lg-12" />
                <textarea
                  type="text"
                  placeholder="Message"
                  className="col-lg-12"
                />
                <button>Submit</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Contact;

// require("file-loader?name=[name].[ext]!../public/index.html");
import React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import store from "./store/index";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { saveState } from "./components/utility/localstorage";
import ScrollToTop from "./components/utility/ScrollToTop";
import throttle from "lodash/throttle";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

store.subscribe(
  throttle(() => {
    saveState({
      root: store.getState().root,
    });
  }, 1000)
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Provider store={store}>
      <ScrollToTop />
      <ReactNotifications />
      <App />
    </Provider>
  </BrowserRouter>
);

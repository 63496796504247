import { PostDataWithToken, GetDataWithToken } from "../../api/apiHelper";
import { mastersEndPoint } from "./endpoints";

import { Store } from "react-notifications-component";





export const getVehicleMake = async (data) => {
  try {
    const response = await PostDataWithToken(mastersEndPoint.make, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getVehicleModel = async (data) => {
  try {
    const response = await PostDataWithToken(mastersEndPoint.model, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getVehicleVariant = async (data) => {
  try {
    const response = await PostDataWithToken(mastersEndPoint.variant, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getVehicleRto = async () => {
  try {
    const response = await GetDataWithToken(mastersEndPoint.rto, "");
    return response;
  } catch (error) {
    return error;
  }
};

export const getVehiclePreviousInsurer = async () => {
  try {
    const response = await GetDataWithToken(mastersEndPoint.previousInsurer, "");
    return response;
  } catch (error) {
    return error;
  }
};

export const getVehiclePincode = async (state) => {
  try {
    const response = await GetDataWithToken(mastersEndPoint.pincode, "");
    return response;
  } catch (error) {
    return error;
  }
};

export const getFiancierBankData = async (state) => {
  try {
    const response = await GetDataWithToken(mastersEndPoint.getFiancierBanks, "");
    return response;
  } catch (error) {
    return error;
  }
};

export const getStates = async (val) => {
  try {
    const response = await GetDataWithToken(mastersEndPoint.state + "?pincode=" + val, "");
    return response;
  } catch (error) {
    return error;
  }
};

export const getBajajMMV = async (data) => {
  try {
    const response = await PostDataWithToken(mastersEndPoint.bajajMMV, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const generateHDFCChecksum = async (data) => {
  try {
    const response = await PostDataWithToken(mastersEndPoint.hdfcChecksum, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const sendErrorMessage = (response) => {
  console.log("response", response.data.message);
  Store.addNotification({
    // title: "Error!",
    message: response.data.message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
  //   swal("Error", response.data.message, "error");
};

export const sendSuccessMessage = (response) => {
  //   swal("Success", response.message, "success");
  Store.addNotification({
    // title: "Seccess!",
    message: response.message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const sendErrorInfo = (message) => {
  //   swal("Error", message, "error");
  Store.addNotification({
    // title: "Error!",
    message: message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const sendSuccessInfo = (message) => {
  //   swal("Success", message, "success");
  Store.addNotification({
    // title: "Seccess!",
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

import React, { useState } from "react";
import Layout from "../../common/Layout";
import "./taxi.css";
import { TabContent, TabPane } from "reactstrap";
import FormTabs from "../../common/FormTabs/FormTabs";

const Taxi = () => {
  const [activeTab, setActiveTab] = useState(1);
  const toggle = (res) => setActiveTab(res);
  const [cities, setCities] = useState([
    {
      id: 1,
      name: "Delhi",
    },
    {
      id: 2,
      name: "Mumbai",
    },
    {
      id: 3,
      name: "Bangluru",
    },
    {
      id: 4,
      name: "Noida, Ghaziabad, Gurgaoun",
    },
    {
      id: 5,
      name: "Chennai",
    },
    {
      id: 6,
      name: "Kolkata",
    },
  ]);

  const [brands, setBrands] = useState([
    {
      id: 1,
      name: "Hero Honda",
    },
    {
      id: 1,
      name: "Hero Honda",
    },
    {
      id: 1,
      name: "Hero Honda",
    },
    {
      id: 1,
      name: "Hero Honda",
    },
    {
      id: 1,
      name: "Hero Honda",
    },
    {
      id: 1,
      name: "Hero Honda",
    },
    {
      id: 1,
      name: "Hero Honda",
    },
  ]);

  const popularModels = [
    {
      id: 1,
      name: "Activa",
    },
    {
      id: 2,
      name: "CB Shine",
    },
    {
      id: 3,
      name: "CB Unicorn",
    },
    {
      id: 4,
      name: "Aviator",
    },
    {
      id: 5,
      name: "Dio",
    },
    {
      id: 6,
      name: "Dream Yuga",
    },
  ];

  const otherModels = [
    {
      id: 1,
      name: "CB",
    },
    {
      id: 2,
      name: "Shine",
    },
    {
      id: 3,
      name: "Unicorn",
    },
    {
      id: 4,
      name: "CB Twister",
    },
    {
      id: 5,
      name: "Activa-1",
    },
    {
      id: 6,
      name: "CB 350 RS",
    },
  ];
  const years = [
    2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011,
    2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003,
  ];

  const firstContent = [
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock",
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary",
    "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum",
    "If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. There is no minimum donation, any sum is appreciated",
  ];
  const secondContent = [
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock",
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary",
    "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum",
    "If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. There is no minimum donation, any sum is appreciated",
  ];
  const firstImage = "./assets/img/taxi-insurance1.png";
  const secondImage = "./assets/img/Frame 63.png";
  return (
    <Layout pageClass={"taxipage"}>
      <div className="form-and-img">
        {/* <div className="hero-text" style={{ minWidth: 250 }}> */}
        <FormTabs vehicleType={"Taxi"} className="form-tabs" />
        {/* </div> */}

        <img
          src="./assets/img/yellow-taxi.png"
          alt="failed-to-load"
          width={"50%"}
        />
      </div>

      <div
        style={{
          margin: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <p className="list-headline" style={{ alignSelf: "center" }}>
          Why Buy From <strong>Expert Cover?</strong>
        </p>
        <div className="card-container">
          <div className="benefit-card">
            <div className="img-circle">
              <img src="./assets//img/medal.png" alt="" />
            </div>
            <div>
              <h2>
                Platinum insurance <br />
                partner
              </h2>
              <p className="card-txt">
                It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages
              </p>
            </div>
          </div>
          <div className="benefit-card">
            <div className="img-circle" style={{ backgroundColor: "#FFF3DD" }}>
              <img src="./assets/img/money-bag 1.png" alt="" />
            </div>
            <div>
              <h2>Affordable options</h2>
              <p className="card-txt">
                It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages
              </p>
            </div>
          </div>
          <div className="benefit-card">
            <div className="img-circle" style={{ backgroundColor: "#FBF1FF" }}>
              <img src="./assets/img/customer-service 1.png" alt="" />
            </div>
            <div>
              <h2>30 minute claim support*</h2>
              <p className="card-txt">
                It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="img-and-content">
        <img
          src={firstImage}
          alt=""
          height={"50%"}
          width={"50%"}
          style={{ flex: "40%" }}
        />
        <div className="bike-insurance-content" style={{ flex: "40%" }}>
          <p className="list-headline">
            Know More About <strong>Taxi Insurance</strong>
          </p>
          <ul>
            {firstContent.map((res) => (
              <>
                <li>{res}</li>
                <br />
              </>
            ))}
          </ul>
        </div>
      </div>

      <div className="content-and-img">
        <div
          className="bike-insurance-content"
          style={{ flex: "40%", marginLeft: 20, marginTop: 50 }}
        >
          <p className="list-headline">
            Benefits of <strong>Taxi Insurance</strong>
          </p>
          <ul>
            {secondContent.map((res) => (
              <>
                <li>{res}</li>
                <br />
              </>
            ))}
          </ul>
        </div>
        <img
          src={secondImage}
          alt=""
          height={"30%"}
          width={"40%"}
          style={{ flex: "40%" }}
        />
      </div>

      <div className="disclaimer">
        <h1>Disclaimer</h1>
        <p className="disclaimer-content">
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages and web
          page editors now use Lorem Ipsum as their default model text, and a
          search for 'lorem ipsum' will uncover many web sites still in their
          infancy. Various versions have evolved over the years, sometimes by
          accident, sometimes on purpose (injected humour and the like).
        </p>
      </div>
    </Layout>
  );
};

export default Taxi;

import React from "react";
import "./aboutUs.css";
import Layout from "../../../common/Layout";
import SubHeader from "../../../common/subHeader/SubHeader";

const AboutUs = () => {
  return (
    <Layout pageClass={"aboutpage"}>
      <SubHeader headline={"About Us"} subHeadline={"About US"} />
      <section id="about-us">
        <div className="about-us-cont">
          <span>
            Finding you the best insurance <br />
            since 2008
          </span>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary,
            <br />
            <br />
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary,
            <br />
          </p>
        </div>
        <div className="about-us-img">
          <img src="./assets/img/familyUmbrella.png" alt="" />
        </div>
      </section>

      <section id="how-to">
        <div className="about-main-heading">
          <h1>How do we do it?</h1>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary,
          </p>
        </div>

        <div className="how-do-we-container">
          <div className="helping-ideas-container">
            <div className="helping-ideas">
              <div className="helping-img">
                <img src="./assets/img/insurance 1.png" alt="" />
              </div>
              <div className="helping-content">
                <h1>Buying Insurance</h1>
                <p>
                  It was popularised in the 1960s with the release of Letraset
                  sheets containing Lorem Ipsum passages
                </p>
              </div>
            </div>

            <div className="helping-ideas">
              <div className="helping-img">
                <img src="./assets/img/mortgage-loan 1.png" alt="" />
              </div>
              <div className="helping-content">
                <h1>Managing Insurance</h1>
                <p>
                  It was popularised in the 1960s with the release of Letraset
                  sheets containing Lorem Ipsum passages
                </p>
              </div>
            </div>

            <div className="helping-ideas">
              <div className="helping-img">
                <img src="./assets/img/handshake 1.png" alt="" />
              </div>
              <div className="helping-content">
                <h1>Claim Settlement</h1>
                <p>
                  It was popularised in the 1960s with the release of Letraset
                  sheets containing Lorem Ipsum passages
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-us-bnft">
        <div className="about-us-img-bnft">
          <img src="./assets/img/goal.png" alt="" />
        </div>
        <div className="about-us-cont-bnft">
          <h1>
            <strong>Our Vision</strong>
          </h1>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary,
          </p>
        </div>
      </section>

      <section id="contact">
        <div className="contact-1">
          <h1>
            The Solution to Your Insurance <br />
            Needs on the Time
          </h1>
        </div>
        <div className="contact-2">
          <div className="contact-2-content">
            <h2>
              Get world-class cover that rewards you with wealth while you're
              alive.
            </h2>
          </div>
          <div className="contact-2-button">
            <button className="contact-button">Get Started</button>
            <button className="contact-button">Let us to contact you</button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutUs;

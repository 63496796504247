import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
// import { dispatchQuickQuote } from '../../../store/actions/userActions'
// import { getStates, getVehiclePincode } from '../../Services/masterServices'
// import { createCustomer } from '../Homepage/TPApiCall'
// import ReactSelect from '../Tags/ReactSelect'
// import { MultiSelect } from 'react-multi-select-component'
import moment from 'moment'
import { PostDataWithToken } from '../../../api/apiHelper'
// import { sendSuccessInfo } from '../../Services/PosService'
// import FutureGeneralKYCRedirect from '../../pages/Additional/FutureGeneralKYCRedirect'
// import LibertyKYCRedirect from '../../pages/Additional/LibertyKYCRedirect'
import { dispatchQuickQuote } from '../../../store/actions/userActions'
import { createCustomer } from '../../services/TPApiCall'
import { getStates, sendSuccessInfo } from '../../services/masterServices'
import ReactSelect from '../InputBox/ReactSelect'
const OwnerDetails = ({activeTab, toggle, state="", pincodeData="" }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({ mode: 'onBlur' })

  console.log(pincodeData, 'pincodes')
  // const [pincodeData, setPincodeData] = React.useState([]);
  const apiRequestQQ = useSelector(state => state.root.apiRequestQQ)
  const selectedPlan = useSelector(state => state.root.selectedPlan)
  const [docType, setDocType] = useState('')
  const [futureKYC, setFutureKyc] = useState(false)
  const [libertyKYC, setLibertyKYC] = useState(false)
  const [futureKYCData, setFutureKYCData] = useState({})
  const [libertyData, setLibertyData] = useState({})
  const [stateName, setState] = useState('')
  const [disabled, setDisabled] = useState('')
  const [pan, setPan] = useState({
    number: '',
    image: '',
    error: ''
  })
  const [ckyc, setCkyc] = useState({
    number: '',
    error: ''
  })
  const [stateData, setStateData] = useState([])

  useEffect(() => {
    reset({
      Email: apiRequestQQ.Email,
      FirstName: apiRequestQQ.FirstName,
      LastName: apiRequestQQ.LastName,
      MobileNumber: apiRequestQQ.MobileNumber,
      PanNumber: apiRequestQQ.PanNumber,
      ...(apiRequestQQ.Dob && { Dob: apiRequestQQ.Dob }),
      ...(apiRequestQQ.Gender && { Gender: apiRequestQQ.Gender }),
      ...(apiRequestQQ.Street && { Street: apiRequestQQ.Street }),
      ...(apiRequestQQ.StreetNumber && {
        StreetNumber: apiRequestQQ.StreetNumber
      }),
      ...(apiRequestQQ.Area && { Area: apiRequestQQ.Area }),
      ...(apiRequestQQ.State && { State: apiRequestQQ.State }),
      ...(apiRequestQQ.District && { District: apiRequestQQ.District })
    })
    if (apiRequestQQ.PanImage != '') {
      setPan({
        image: 'data:image/jpeg;base64,' + apiRequestQQ.PanImage,
        number: apiRequestQQ.PanNumber
      })
    }
    if (selectedPlan.Api_name === 'HDFC' && apiRequestQQ.PanNumber) {
      checkHDFCKYCStatus(apiRequestQQ.PanNumber, false)
    }

    if (selectedPlan.Api_name == 'Royal') {
      if (apiRequestQQ.Dob != '' && apiRequestQQ.Street != '') {
        royalSundramKYCDetails(
          apiRequestQQ.FirstName,
          apiRequestQQ.LastName,
          apiRequestQQ.Dob,
          apiRequestQQ.PanNumber,
          false
        )
      }
    }
    if (selectedPlan.Api_name == 'Liberty') {
      if (
        apiRequestQQ.KYC.Liberty != '' &&
        apiRequestQQ.KYC.LibertyRefNo != ''
      ) {
        getLibertyKYC(null, false)
      }
    }
  }, [])

  const handleSelectState = val => {
    setState(val.label)
    dispatchQuickQuote('State', val)
    let index = stateData.findIndex(item => item.STATEDESC == val)
    if (index > -1) {
      dispatchQuickQuote('StateCode', stateData[index].Digit_Code)
    }
  }

  const handleSelectPincode = val => {
    reset({ City: val.City })
    dispatchQuickQuote('Pincode', val.value)

    dispatchQuickQuote('District', val.District)
    dispatchQuickQuote('City', val.City)
    getStates(val.value)
      .then(response => {
        if (response.status === true) {
          let stats = response.data[0]
          setStateData(response.data[0])
          reset({ State: stats.STATEDESC })
          dispatchQuickQuote('State', stats.STATEDESC)
          dispatchQuickQuote('StateCode', stats.Digit_Code)
        }
      })
      .catch(err => console.log(err))
  }

  const handleSaveUser = data => {
    if (apiRequestQQ.PanImage == '') {
      return setPan({ ...pan, error: 'Pan Image is required' })
    }
    for (let key in data) {
      if (key != 'KycNumber') {
        dispatchQuickQuote(key, data[key])
      } else if (key == 'KycNumber') {
        dispatchQuickQuote('KYC.' + 'Shriram', data[key] || '')
      }
    }
    let postData = {
      name: data.FirstName + ' ' + data.LastName,
      email: data.Email,
      phone: data.MobileNumber,
      dob: data.Dob,
      city: data.City,
      state: stateName,
      address_line1: data.StreetNumber,
      address_line2: data.Street,
      address_line3: data.Area,
      customerId: apiRequestQQ.customerId,
      customer_type: apiRequestQQ.CustomerType,
      pan_card: data.PanNumber,
      ckyc: data.ckyc,
      gst_no: data.GSTNo,
      pincode: apiRequestQQ.Pincode
    }

    createCustomer(postData)
    if (selectedPlan.Api_name === 'HDFC') {
      checkHDFCKYCStatus(data.PanNumber, true)
    } else if (selectedPlan.Api_name === 'bajaj') {
      console.log(data, 'Data Print')
      checkBajajKYCStatus(
        // data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        // data?.PanNumber ? 'C' : 'Z',
        data?.PanNumber ? 'C' : 'E',
        data.PanNumber ? data.PanNumber : data.ckycNumber,
        data.Dob,
        data.Gender
      )
    } else if (selectedPlan.Api_name == 'Future') {
      FutureGeneralUserKyc(
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data.Dob,
        postData.name,
        data.Gender
      )
    } else if (selectedPlan.Api_name == 'Reliance') {
      checkRelianceKYCDetails(
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data.Dob
      )
    } else if (selectedPlan.Api_name == 'Kotak') {
      checkKotakKycDetails(
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data
      )
    } else if (selectedPlan.Api_name == 'Royal') {
      royalSundramKYCDetails(
        data.FirstName,
        data.LastName,
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data.Dob,
        true
      )
    } else if (selectedPlan.Api_name == 'Zuno') {
      getZunoKyc(data, apiRequestQQ.Pincode)
    } else if (selectedPlan.Api_name == 'Liberty') {
      getLibertyKYC(data, true)
    } else {
      setTimeout(() => {
        apiRequestQQ.CustomerType == 'INDIVIDUAL'
          ? toggle(activeTab + 1)
          : toggle(activeTab + 2)
      }, 1000)
    }
    // setTimeout(() => {
    //   toggle(activeTab + 1);
    // }, 400);
  }

  const checkHDFCKYCStatus = (pannumber, redirect) => {
    PostDataWithToken('kyc/hdfc-kyc', { PanNumber: pannumber }).then(
      response => {
        if (response.status === true) {
          console.log('hdfc kyc response', response.data.kyc_id)
          if (response.data.iskycVerified == 1) {
            dispatchQuickQuote('KYC.' + 'HDFC', response.data.kyc_id)
            dispatchQuickQuote('FirstName', response.data?.name.split(' ')[0])
            dispatchQuickQuote('LastName', response.data?.name.split(' ')[1])
            dispatchQuickQuote('PanNumber', response.data?.pan)
            dispatchQuickQuote(
              'Dob',
              moment(response.data?.dob, 'DD/MM/YYYY').format('YYYY-MM-DD')
            )
            reset({
              FirstName: response.data?.name.split(' ')[0],
              lastName: response.data?.name.split(' ')[1],
              Dob: moment(response.data?.dob, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              ),
              PanNumber: response.data.pan
            })
            if (redirect === true) {
              setTimeout(() => {
                toggle(activeTab + 1)
              }, 500)
            }
          } else {
            alert('You have to complete your HDFC E-kyc first')
            window.open(response.data.redirect_link, '_blank')
          }
        }
      }
    )
  }
  const checkBajajKYCStatus = (docTypeCode, docNumber, dob, gender) => {
    let postData = {
      docTypeCode: docTypeCode,
      docNumber: docNumber,
      fieldValue: selectedPlan.ApiId,
      dob: dob,
      gender: gender == 'Male' ? 'M' : 'F',
      customerType: apiRequestQQ.CustomerType === 'INDIVIDUAL' ? 'I' : 'C',
      vehicleType: apiRequestQQ.VehicleType,
      IsVehicleNew: apiRequestQQ.IsVehicleNew,
      policyType: apiRequestQQ.NewPolicyType,
      name: apiRequestQQ?.FirstName + ' ' + apiRequestQQ?.LastName
    }
    PostDataWithToken('kyc/bajaj-kyc', postData).then(response => {
      if (response.status === true) {
        const { ckycNumber, ckycStatus, errCode } = response.data
        console.log('cyck', ckycNumber && errCode == '0')
        if (ckycNumber == null && ckycStatus == 'NA') {
          let panImage = apiRequestQQ.PanImage
          let kycData = {
            fieldValue: selectedPlan.ApiId,
            PanNumber: pannumber,
            PanImage: panImage
          }
          PostDataWithToken('kyc/bajaj-kyc-document', kycData).then(result => {
            if (result.data.errorCode == '0') {
              toggle(activeTab + 1)
            } else {
              alert('Please recheck your information and try again')
            }
          })
        } else if (ckycNumber && errCode == '0') {
          toggle(activeTab + 1)
        } else {
          alert('Please recheck your information and try again')
        }
      }
    })
  }

  const handleUploadPanFile = async file => {
    const base64Img = await toBase64(file[0])
    setPan({ ...pan, image: base64Img, error: '' })
    dispatchQuickQuote('PanImage', base64Img.split(',')[1])
  }

  const handleUploadAdharFile = async file => {
    const base64Img = await toBase64(file[0])
    setAdhar({ ...adhar, image: base64Img, error: '' })
    dispatchQuickQuote('AdharImage', base64Img.split(',')[1])
  }

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      console.log('file', file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  const handleChangePanValue = value => {
    setPan({ ...pan, number: value, error: '' })
  }

  const handleChangeCkycValue = value => {
    setCkyc({ ...ckyc, number: value, error: '' })
  }

  const FutureGeneralUserKyc = (pannumber, dob, full_name, gender) => {
    setDisabled('disabled')
    let postData = {
      customer_type: apiRequestQQ.CustomerType === 'INDIVIDUAL' ? 'I' : 'C',
      PanNumber: pannumber,
      dob: dob,
      full_name: full_name,
      gender: gender == 'Male' ? 'M' : 'F'
    }
    PostDataWithToken('kyc/future-general-kyc', postData).then(response => {
      if (response.status === true) {
        console.log('resposen data', response.data)
        let { result, proposal_id, url, req_id } = response.data
        if (result?.ckyc_number) {
          setDisabled('')
          sendSuccessInfo(
            'Your Future KYC is successfully generated ' + result?.ckyc_number
          )
          dispatchQuickQuote('KYC.' + 'Future', result?.ckyc_number)
          toggle(activeTab + 1)
        } else if (isValidHttpUrl(url) === true) {
          setDisabled('')
          setFutureKyc(true)
          setFutureKYCData({
            kycno: req_id,
            proposalNo: proposal_id,
            url: url
          })
          // window.open(url, "_blank");
        } else if (proposal_id) {
          PostDataWithToken('kyc/future-general-kycStatus', {
            proposal_id: proposal_id
          }).then(response => {
            if (
              response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO
            ) {
              if (response.status === true) {
                sendSuccessInfo(
                  'Your Future KYC is successfully generated ' +
                    response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS
                      ?.CKYC_NO
                )
                dispatchQuickQuote(
                  'KYC.' + 'Future',
                  response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS
                    ?.CKYC_NO
                )
                toggle(activeTab + 1)
                setDisabled('')
              } else {
                setDisabled('')
                alert('KYC is not generated please try again ')
              }
            }
          })
        }
      }
    })
  }

  const checkRelianceKYCDetails = (pan, dob) => {
    setDisabled('disabled')
    let postData = {
      PanNumber: pan,
      DOB: moment(dob, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      ReturnURL: 'https://api.expertcover.in/proposal'
    }
    PostDataWithToken('kyc/reliance-kyc', postData)
      .then(response => {
        if (response.status === true) {
          setDisabled('')
          if (
            response.data.success == true &&
            response.data.KYC_Verified == 'true'
          ) {
            let kycNumber =
              response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO
            dispatchQuickQuote('KYC.' + 'Reliance', kycNumber)
            apiRequestQQ.CustomerType == 'INDIVIDUAL'
              ? toggle(activeTab + 1)
              : toggle(activeTab + 2)
          } else if (response.data.Endpoint_2_URL) {
            alert('You have to complete your Reliance E-kyc first')
            window.location.href = response.data.Endpoint_2_URL
          } else {
            alert(response.data.message)
          }
        } else {
          setDisabled('')
        }
      })
      .catch(err => setDisabled(''))
  }

  const checkKotakKycDetails = (pannumber, formData) => {
    setDisabled('disabled')
    let postData = {
      PanNumber: pannumber,
      DOB: formData.Dob,
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      CINNumber: formData.CINNumber,
      ProposalNumber: '',
      ClientCallBackURL: 'https://api.expertcover.in/proposal',
      requestId: apiRequestQQ.ApiId,
      Gender: formData.Gender === 'Male' ? 'M' : 'F',
      CustomerType: apiRequestQQ.CustomerType === 'COMPANY' ? 'C' : 'I'
    }
    PostDataWithToken('kyc/kotak-kyc', postData)
      .then(response => {
        if (response.status === true) {
          if (
            response.data.KYCNumber &&
            response.data.KYCStatus == 'CKYCSuccess'
          ) {
            setDisabled('')
            dispatchQuickQuote('KYC.' + 'Kotak', response.data.KYCNumber)
            const {
              KYCCorAdd1,
              KYCCorAdd2,
              KYCFirstName,
              KYCLastName,
              KYCMiddleName
            } = response.data
            KYCFirstName && dispatchQuickQuote('FirstName', KYCFirstName)
            KYCLastName && dispatchQuickQuote('LastName', KYCLastName)
            KYCLastName && dispatchQuickQuote('MiddleName', KYCMiddleName)
            // KYCCorAdd1 && dispatchQuickQuote("StreetNumber", KYCCorAdd1);
            // KYCCorAdd2 && dispatchQuickQuote("Street", KYCCorAdd2);
            // verifyKotakApi(apiRequestQQ.ApiId, response.data.TokenId);
            apiRequestQQ.CustomerType == 'INDIVIDUAL'
              ? toggle(activeTab + 1)
              : toggle(activeTab + 2)
          } else if (response.data.RequestURL) {
            alert('You have to complete your Kotak E-kyc first')
            window.location.href = response.data.RequestURL
            setDisabled('')
          } else {
            setDisabled('')
            alert(
              response.data?.ExceptionErrorMsg
                ? response.data?.ExceptionErrorMsg
                : 'Please try again'
            )
          }
        }
      })
      .catch(err => setDisabled(''))
  }

  const verifyKotakApi = (requestid, tokenId) => {
    let postData = {
      requestid: requestid,
      tokenId: tokenId
    }
    PostDataWithToken('kyc/verify-kotak-kyc', postData).then(response => {
      if (response.status === true) {
        console.log(response.data)
      }
    })
  }

  // Royal Sundram API
  const royalSundramKYCDetails = (fname, lname, pan, dob, redirect) => {
    setDisabled('disabled')
    let postData = {
      FirstName: fname,
      LastName: lname,
      PanNumber: pan,
      Dob: dob,
      QuoteId: apiRequestQQ.ApiId,
      CustomerType: apiRequestQQ.CustomerType === 'INDIVIDUAL' ? 'I' : 'C',
      ReturnURL: 'https://api.expertcover.in/proposal'
    }
    PostDataWithToken('kyc/royal-kyc-status', {
      QuoteId: apiRequestQQ.ApiId
    }).then(response => {
      if (response.data.applicationStatus != 'Success') {
        PostDataWithToken('kyc/royal-sundram-kyc', postData)
          .then(response => {
            if (response.status === true) {
              if (response.data.url != null) {
                setDisabled('')
                alert('You have to complete your Royal Sundram E-kyc first')
                window.location.href = response.data.url
              } else if (response.data.kycStatus === true) {
                const { address1, address2, city, state, pinCode } =
                  response.data.kycDetails
                let gender =
                  response.data?.kycDetails.gender == 'M' ? 'Male' : 'Female'
                console.log(
                  'response.data?.kycDetails.dob',
                  response.data?.kycDetails.dob
                )
                sendSuccessInfo(
                  'Your Royal Sundram KYC is successfully generated ' +
                    response.data?.kycDetails.ckycNo
                )
                dispatchQuickQuote(
                  'KYC.' + 'Royal',
                  response.data?.kycDetails.ckycNo
                )
                dispatchQuickQuote(
                  'KYC.' + 'RoyalRefNo',
                  response.data?.kycRefNo
                )
                dispatchQuickQuote(
                  'FirstName',
                  response.data?.kycDetails.firstName
                )
                dispatchQuickQuote(
                  'LastName',
                  response.data?.kycDetails.lastName
                )
                dispatchQuickQuote(
                  'MiddleName',
                  response.data?.kycDetails.middleName
                )
                dispatchQuickQuote('Gender', gender)
                dispatchQuickQuote(
                  'Dob',
                  moment(
                    response.data?.kycDetails.dateOfBirth,
                    'DD-MM-YYYY'
                  ).format('YYYY-MM-DD')
                )

                reset({
                  FirstName: response.data?.kycDetails.firstName,
                  lastName: response.data?.kycDetails.lastName,
                  Dob: moment(
                    response.data?.kycDetails.dob,
                    'DD-MM-YYYY'
                  ).format('YYYY-MM-DD'),
                  Gender: gender
                })

                dispatchQuickQuote(
                  'PermanentAddress',
                  JSON.stringify({
                    address1: address1,
                    address2: address2,
                    city: city,
                    state: state,
                    pinCode: pinCode
                  })
                )

                redirect === true && apiRequestQQ.CustomerType == 'INDIVIDUAL'
                  ? toggle(activeTab + 1)
                  : redirect === true && toggle(activeTab + 2)
                setDisabled('')
              } else {
                setDisabled('')
                alert('KYC not done please retry again')
              }
            }
          })
          .catch(err => {
            console.log('err', err)
            setDisabled('')
          })
      } else if (response.data.applicationStatus == 'Success') {
        const { address1, address2, city, state, pinCode } = response.data
        let gender = response.data?.gender == 'M' ? 'Male' : 'Female'
        dispatchQuickQuote('KYC.' + 'Royal', response.data.ckycNo)
        dispatchQuickQuote('KYC.' + 'RoyalRefNo', response.data?.kycRefNo)
        dispatchQuickQuote('FirstName', response.data.firstName)
        dispatchQuickQuote('LastName', response.data.lastName)
        dispatchQuickQuote('MiddleName', response.data.middleName)
        dispatchQuickQuote(
          'Dob',
          moment(response.data?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD')
        )
        dispatchQuickQuote('Gender', gender)
        dispatchQuickQuote(
          'PermanentAddress',
          JSON.stringify({
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            pinCode: pinCode
          })
        )
        reset({
          FirstName: response.data?.firstName,
          lastName: response.data?.lastName,
          Dob: moment(response.data?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          Gender: gender
        })
        redirect === true && apiRequestQQ.CustomerType == 'INDIVIDUAL'
          ? toggle(activeTab + 1)
          : redirect === true && toggle(activeTab + 2)
        setDisabled('')
      } else {
        setDisabled('')
      }
    })
  }

  // zuno api request
  const getZunoKyc = (data, Pincode) => {
    let postData = {
      CustomerType: apiRequestQQ.CustomerType === 'INDIVIDUAL' ? 'I' : 'C',
      ReturnURL: 'https://api.expertcover.in/proposal',
      FirstName: data.FirstName,
      LastName: data.LastName,
      Email: data.Email,
      PanNumber: data.PanNumber,
      PinCode: Pincode,
      MobileNo: data.MobileNumber,
      Gender: data.Gender,
      Dob: data.Dob
    }
    PostDataWithToken('kyc/zuno-kyc', postData).then(response => {
      if (response.status === true) {
        if (response.data.data.IC_KYC_REG_URL) {
          window.location.href = response.data.data.IC_KYC_REG_URL
        } else {
          dispatchQuickQuote('KYC.' + 'Zuno', response.data.data.IC_KYC_No)
          dispatchQuickQuote(
            'KYC.' + 'ZunoRef',
            response.data.data.VISoF_KYC_Req_No
          )
          apiRequestQQ.CustomerType == 'INDIVIDUAL'
            ? toggle(activeTab + 1)
            : toggle(activeTab + 2)
        }
      }
    })
  }

  const getLibertyKYC = (data, redirect) => {
    let reqData = {
      Liberty: apiRequestQQ.KYC.Liberty,
      LibertyRefNo: apiRequestQQ.KYC.LibertyRefNo
    }
    PostDataWithToken('kyc/liberty-kyc-search', reqData).then(response => {
      if (response.status === true) {
        if (response.data.KYC_Status != 0) {
          const {
            Aggregator_KYC_Req_No,
            DOB,
            FirstName,
            Gender,
            MiddleName,
            LastName,
            IC_KYC_No,
            KYC_Status,
            ProposerPAN
          } = response.data
          if (KYC_Status == 1) {
            dispatchQuickQuote('KYC.' + 'Liberty', IC_KYC_No)
            dispatchQuickQuote('KYC.' + 'LibertyRefNo', Aggregator_KYC_Req_No)
            dispatchQuickQuote('FirstName', FirstName)
            dispatchQuickQuote('LastName', LastName)
            dispatchQuickQuote('MiddleName', MiddleName)
            dispatchQuickQuote('Gender', Gender == 'M' ? 'Male' : 'Female')
            dispatchQuickQuote(
              'Dob',
              moment(DOB, 'MM/DD/YYYY').format('YYYY-MM-DD')
            )
            dispatchQuickQuote('PanNumber', ProposerPAN)
            reset({
              FirstName: FirstName,
              LastName: LastName,
              Dob: moment(DOB, 'MM/DD/YYYY').format('YYYY-MM-DD'),
              Gender: Gender == 'M' ? 'Male' : 'Female',
              PanNumber: ProposerPAN
            })
            redirect === true && apiRequestQQ.CustomerType == 'INDIVIDUAL'
              ? toggle(activeTab + 1)
              : redirect === true && toggle(activeTab + 2)
          }
        } else if (redirect == true) {
          let postData = {
            CustomerType: apiRequestQQ.CustomerType,
            Dob: data?.Dob,
            FirstName: data?.FirstName,
            LastName: data?.LastName,
            MobileNo: data?.MobileNumber,
            Email: data?.Email,
            PanNumber: data?.PanNumber,
            Gender: data?.Gender,
            ReturnURL: 'https://api.expertcover.in/proposal'
          }
          PostDataWithToken('kyc/liberty-kyc', postData).then(response => {
            if (response.status === true) {
              const {
                Aggregator_KYC_Req_No,
                DOB,
                FirstName,
                Gender,
                MiddleName,
                LastName,
                IC_KYC_No,
                ProposerPAN
              } = response.data
              if (
                response.data.IC_KYC_REG_URL &&
                response.data.KYC_Status != '1'
              ) {
                setLibertyKYC(true)
                dispatchQuickQuote('KYC.' + 'Liberty', IC_KYC_No)
                dispatchQuickQuote(
                  'KYC.' + 'LibertyRefNo',
                  Aggregator_KYC_Req_No
                )
                setLibertyData({
                  kycno: response.data.IC_KYC_No,
                  proposalNo: response.data.Aggregator_KYC_Req_No,
                  url: response.data.IC_KYC_REG_URL
                })
              } else if (response.data.KYC_Status != 0) {
                dispatchQuickQuote('KYC.' + 'Liberty', IC_KYC_No)
                dispatchQuickQuote(
                  'KYC.' + 'LibertyRefNo',
                  Aggregator_KYC_Req_No
                )
                dispatchQuickQuote('FirstName', FirstName)
                dispatchQuickQuote('LastName', LastName)
                dispatchQuickQuote('MiddleName', MiddleName)
                dispatchQuickQuote('Gender', Gender == 'M' ? 'Male' : 'Female')
                dispatchQuickQuote(
                  'Dob',
                  moment(DOB, 'MM/DD/YYYY').format('YYYY-MM-DD')
                )
                dispatchQuickQuote('PanNumber', ProposerPAN)
                reset({
                  FirstName: FirstName,
                  lastName: LastName,
                  Dob: moment(DOB, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                  Gender: Gender == 'M' ? 'Male' : 'Female',
                  PanNumber: ProposerPAN
                })
                redirect === true && apiRequestQQ.CustomerType == 'INDIVIDUAL'
                  ? toggle(activeTab + 1)
                  : redirect === true && toggle(activeTab + 2)
              }
            }
          })
        }
      }
    })
  }
  return (
    <div className='card p-4 mt-3'>
   
        <form onSubmit={handleSubmit(handleSaveUser)}>
          <p className='mb-0 fs-1 fw-bold'>Personal Details</p>
          <div className='othervoption mt-3'>
            <div className='row mx-0 px-0'>
              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating'>
                    <select
                      {...register('Salutation', {
                        required: 'Salutation is required'
                      })}
                      className='form-select'
                      id='gen'
                    >
                      {apiRequestQQ.CustomerType === 'INDIVIDUAL' ? (
                        <>
                          <option value='Mr' selected>
                            MR.
                          </option>
                          <option value='Miss'>MISS.</option>
                          <option value='Mrs'>MRS.</option>
                        </>
                      ) : (
                        <option value='M/S' selected>
                          M/S
                        </option>
                      )}
                    </select>
                    <label htmlFor='gen'>
                      Salutation
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                  <p className='m-0 f-error'>{errors?.Salutation?.message}</p>
                </div>
              </div>

              <div className='col-lg-4'>
                <div className='position-relative mb-5'>
                  <div className='form-floating'>
                    <input
                      type='text'
                      className='form-control'
                      {...register('FirstName', {
                        required: 'First Name is required'
                      })}
                      readOnly={
                        apiRequestQQ.KYC.RoyalRefNo != '' &&
                        selectedPlan.Api_name == 'Royal'
                          ? true
                          : false
                      }
                      id='fsa1'
                      placeholder='Owner Nam as m'
                    />
                    <label htmlFor='fsa1'>
                      First Name
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                  <p className='m-0 f-error'>{errors?.FirstName?.message}</p>
                </div>
              </div>

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <input
                      type='text'
                      className='form-control'
                      {...register('LastName', {
                        // required: "Last Name is required",
                      })}
                      readOnly={
                        apiRequestQQ.KYC.RoyalRefNo != '' &&
                        selectedPlan.Api_name == 'Royal'
                          ? true
                          : false
                      }
                      id='fsa1s'
                      placeholder='Owner Nam as m'
                    />
                    <label htmlFor='fsa1s'>
                      Last Name
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                  <p className='m-0 f-error'>{errors?.LastName?.message}</p>
                </div>
              </div>
              {apiRequestQQ.CustomerType !== 'COMPANY' && (
                <div className='col-lg-4 '>
                  <div className='position-relative mb-5'>
                    <div className='form-floating'>
                      <select
                        {...register('Gender', {
                          required: 'Gender is required'
                        })}
                        readOnly={
                          apiRequestQQ.KYC.RoyalRefNo != '' &&
                          selectedPlan.Api_name == 'Royal'
                            ? true
                            : false
                        }
                        className='form-select'
                        id='gen'
                      >
                        <option className='d-none' selected value=''>
                          Select Gender
                        </option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                      </select>
                      <label htmlFor='gen'>
                        Gender
                        <span className='text-danger'>*</span>
                      </label>
                    </div>
                    <p className='m-0 f-error'>{errors?.Gender?.message}</p>
                  </div>
                </div>
              )}

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <input
                      type='date'
                      className='datepicker form-control'
                      readOnly={
                        apiRequestQQ.KYC.RoyalRefNo != '' &&
                        selectedPlan.Api_name == 'Royal'
                          ? true
                          : false
                      }
                      {...register('Dob', {
                        required:
                          selectedPlan.Api_name == 'Kotak' &&
                          apiRequestQQ.CustomerType === 'COMPANY'
                            ? 'Field is required'
                            : 'Date of Birth is required'
                      })}
                      max={
                        apiRequestQQ.CustomerType === 'COMPANY'
                          ? moment().format('YYYY-MM-DD')
                          : moment().subtract('18', 'year').format('YYYY-MM-DD')
                      }
                      id='dob'
                      placeholder='Date of birth'
                    />
                    <label htmlFor='dob'>
                      {apiRequestQQ.CustomerType === 'COMPANY'
                        ? 'Incorporation Year'
                        : 'Date of Birth'}
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                  <p className='m-0 f-error'>{errors?.Dob?.message}</p>
                </div>
              </div>

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <input
                      type='text'
                      {...register('MobileNumber', {
                        required: 'Mobile Number is required',
                        pattern: {
                          value:
                            /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                          message: 'Invalid Mobile Number'
                        }
                      })}
                      className='form-control'
                      id='fsaqw'
                      placeholder='Mobile Number'
                      maxLength='10'
                    />
                    <label htmlFor='fsaqw'>
                      Mobile Number
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                  <p className='m-0 f-error'>{errors?.MobileNumber?.message}</p>
                </div>
              </div>

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <input
                      type='email'
                      className='form-control'
                      {...register('Email', {
                        required: 'Email is required',
                        pattern: {
                          value:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                          message: 'Invalid Email'
                        }
                      })}
                      id='fsaemail'
                      placeholder='Enter email'
                    />
                    <label htmlFor='fsaemail'>
                      Email Address
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                  <p className='m-0 f-error'>{errors?.Email?.message}</p>
                </div>
              </div>

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <input
                      type='text'
                      className='form-control'
                      {...register('StreetNumber')}
                      id='fsa1m'
                      placeholder='Owner Nam as m'
                    />
                    <label htmlFor='fsa1m'>
                      House/Street Number
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <input
                      type='text'
                      className='form-control'
                      {...register('Street')}
                      id='fsa1ss'
                      placeholder='Owner Nam as m'
                    />
                    <label htmlFor='fsa1ss'>
                      Street
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <input
                      type='text'
                      className='form-control'
                      {...register('Area')}
                      id='fsa1ar'
                      placeholder='Owner Nam as m'
                    />
                    <label htmlFor='fsa1ar'>
                      Area
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <ReactSelect
                      options={pincodeData}
                      onChange={val => handleSelectPincode(val)}
                      placeholder='Select area pincode'
                      defaultValue={apiRequestQQ.Pincode}
                    />
                  </div>
                </div>
              </div>

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <input
                      list='browsers'
                      {...register('State')}
                      onChange={e => handleSelectState(e.target.value)}
                      className='form-control'
                      name='myBrowser'
                    />
                    <label htmlFor='fsa'>
                      State
                      <span className='text-danger'>*</span>
                    </label>
                    <datalist id='browsers'>
                      {stateData &&
                        stateData.length &&
                        stateData.map((item, index) => {
                          return (
                            <option
                              onClick={() => handleSelectState(item)}
                              key={index}
                            >
                              {item?.PC_CODE}
                            </option>
                          )
                        })}
                    </datalist>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 '>
                <div className='position-relative mb-5'>
                  <div className='form-floating '>
                    <input
                      type='text'
                      className='form-control'
                      {...register('City')}
                      id='fsa'
                      placeholder='communication address'
                    />
                    <label htmlFor='fsa'>
                      City
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                </div>
              </div>

              {apiRequestQQ.CustomerType === 'COMPANY' && (
                <div className='col-lg-4 '>
                  <div className='position-relative mb-5'>
                    <div className='form-floating '>
                      <input
                        type='text'
                        className='form-control'
                        {...register('GSTNo', {
                          required: 'Field is required'
                        })}
                        id='fsa'
                        placeholder='Company GST Number'
                      />
                      <label htmlFor='fsa'>
                        Company GST No.
                        <span className='text-danger'>*</span>
                      </label>
                    </div>
                    <p className='m-0 f-error'>{errors?.GSTNo?.message}</p>
                  </div>
                </div>
              )}

              <>
                {selectedPlan.Api_name == 'Shriram' && (
                  <div className='col-lg-4 '>
                    <div className='position-relative mb-5'>
                      <div className='form-floating'>
                        <input
                          type='text'
                          className='form-control'
                          id='fsa1'
                          {...register('KycNumber')}
                          placeholder='KYC Number'
                        />
                        <label htmlFor='fsa1'>
                          KYC Number
                          <span className='text-danger'>*</span>
                        </label>
                      </div>
                      <p className='m-0 f-error'>
                        {errors?.KycNumber?.message}
                      </p>
                    </div>
                  </div>
                )}
                {selectedPlan.Api_name == 'Kotak' &&
                  apiRequestQQ.CustomerType === 'COMPANY' && (
                    <div className='col-lg-4 '>
                      <div className='position-relative mb-5'>
                        <div className='form-floating '>
                          <input
                            type='text'
                            {...register('CINNumber', {
                              required: 'CIN Number is required'
                            })}
                            className='form-control'
                            id='fsaqw13'
                            placeholder='CIN Number'
                          />
                          <label htmlFor='fsaqw13'>
                            CIN Number
                            <span className='text-danger'>*</span>
                          </label>
                        </div>
                        <p className='m-0 f-error'>
                          {errors?.CINNumber?.message}
                        </p>
                      </div>
                    </div>
                  )}

                {/* Doc Type */}

                <div className='col-lg-4 '>
                  <div className='position-relative mb-5'>
                    <div className='form-floating'>
                      <select
                        className='form-select'
                        id='gen'
                        {...register('docType', {
                          required: 'Please Select Document Type to Verify',
                          onChange: e => setDocType(e?.target?.value)
                        })}
                      >
                        <option value=''>Select Document Type</option>
                        <option value='ckyc'>CKYC Number</option>
                        <option value='pan'>Pan Card</option>
                      </select>
                      <label htmlFor='gen'>
                        Verify By...
                        <span className='text-danger'>*</span>
                      </label>
                    </div>
                    <p className='m-0 f-error'>{errors?.docType?.message}</p>
                  </div>
                </div>
                {docType === 'ckyc' ? (
                  <div className='col-lg-4 '>
                    <div className='position-relative mb-5'>
                      <div className='form-floating'>
                        <input
                          type='text'
                          className='form-control'
                          id='fsa1'
                          {...register('ckycNumber', {
                            pattern: {
                              value: /[0-9]$/,
                              message: 'Invalid CKYC Number'
                            }
                          })}
                          // readOnly={
                          //   apiRequestQQ.KYC.RoyalRefNo != '' &&
                          //   selectedPlan.Api_name == 'Royal'
                          //     ? true
                          //     : false
                          // }
                          onChange={e => handleChangeCkycValue(e.target.value)}
                          placeholder='Pan Car Number'
                        />
                        <label htmlFor='fsa1'>CKYC Number</label>
                      </div>
                      {/* <p className='m-0 f-error'>{errors?.PanNumber?.message}</p> */}
                    </div>
                  </div>
                ) : docType === 'pan' ? (
                  <>
                    <div className='col-lg-4 '>
                      <div className='position-relative mb-5'>
                        <div className='form-floating'>
                          <input
                            type='text'
                            className='form-control'
                            id='fsa1'
                            {...register('PanNumber', {
                              pattern: {
                                value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                message: 'Invalid Pan Number'
                              }
                            })}
                            readOnly={
                              apiRequestQQ.KYC.RoyalRefNo != '' &&
                              selectedPlan.Api_name == 'Royal'
                                ? true
                                : false
                            }
                            onChange={e => handleChangePanValue(e.target.value)}
                            placeholder='Pan Car Number'
                          />
                          <label htmlFor='fsa1'>Pan Card Number</label>
                        </div>
                        <p className='m-0 f-error'>
                          {errors?.PanNumber?.message}
                        </p>
                      </div>
                    </div>

                    <div className='col-lg-4 col-sm-4  col-6 mb-5'>
                      <input
                        type='file'
                        className='d-none'
                        id={'panImage'}
                        name={'panImage'}
                        onChange={e => handleUploadPanFile(e.target.files)}
                        accept={'image/*'}
                      />

                      <label htmlFor={'panImage'} className=''>
                        <p className='fs-2 text-decoration-underline fw-bold'>
                          Pan Card
                        </p>
                        <img
                          src={
                            pan.image ? pan.image : './assets/images/Addimg.png'
                          }
                          className=' d-block'
                          style={{
                            objectFit: 'contain',
                            height: '150px',
                            width: '150px'
                          }}
                        />
                      </label>
                      <p className='m-0 f-error'>{pan.error}</p>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>

              <div className='col-lg-12 '>
                <div className='col-lg-2 col-sm-6 mx-lg-0 mx-auto col-12 px-lg-0'>
                  <button
                  onClick={()=>toggle(activeTab+1)}
                    disabled={disabled}
                    type='submit'
                    className='btn btn-primary mb-5 mt-3 py-3 w-100 fs-1 fw-bold'
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
    </div>
  )
}

export default OwnerDetails
function isValidHttpUrl (string) {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

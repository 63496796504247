import React, { Component } from "react";
import Select, { components } from "react-select";
import { FixedSizeList as List } from "react-window";
const ReactSelect = ({ options, name, placeholder, defaultValue, onChange = () => {} }) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src="./assets/img/modalsearch.png" alt="" />
      </components.DropdownIndicator>
    );
  };
  return (
    <Select
      isSearchable
      options={options}
      components={{ MenuList, DropdownIndicator }}
      onChange={(val) => onChange(val)}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  );
};

export default ReactSelect;

const height = 35;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List height={maxHeight} itemCount={children.length} itemSize={height} initialScrollOffset={initialOffset}>
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

import React, { useEffect, useState } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import './formTabs.css'
import {
  getVehicleMake,
  getVehicleModel,
  getVehiclePreviousInsurer,
  getVehicleRto,
  getVehicleVariant
} from '../../services/masterServices'
import {
  dispatchQuickQuote,
  generateArrayOfYears
} from '../../../store/actions/userActions'
import { useSelector } from 'react-redux'
import moment from 'moment/moment'
import {
  bikePolicyInfoArr,
  fourWheelerModel,
  gcvVehiclePolicyInfoArr,
  pcvVehiclePolicyInfoArr,
  pvtCarPolicyInfoArr,
  twoWheelerMake
} from '../MakeModelArr'
import ReactSelect from '../InputBox/ReactSelect'
import QuotePolicyDetails from '../QuotePolicyDetails'
import { Link, useNavigate } from 'react-router-dom'
import {
  FutureGeneralQuickQuote,
  HDFCCalculatePremium,
  KotakAPICall,
  RelianceAPICall,
  RoyalSundramAPICall,
  bajajApiCall,
  createCustomer,
  digitAPICall,
  getLibertyAPICall,
  getZunoQuickQuote,
  savePolicyToDatabase,
  shriramGenerateProposal
} from '../../services/TPApiCall'
import { useForm } from 'react-hook-form'

function FormTabs ({
  vehicleType,
  modal,
  setModal,
  VehicleModel,
  toggleModal = () => {}
}) {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(1)
  const toggle = tab => setActiveTab(tab)
  const apiRequestQQ = useSelector(state => state.root.apiRequestQQ)
  const [vehicleModel, setVehicleModel] = useState('')
  const [quickPick, setQuickPick] = useState([])
  const [makeData, setMakeData] = useState([])
  const [modelData, setModelData] = useState([])
  const [variantData, setVariantData] = useState([])
  const [insurerData, setInsurerData] = useState([])
  const [rtoData, setRtoData] = useState([])
  const [RegistrationNumber, setRegistrationNumner] = useState('')
  const [isNewVehicle, setIsNewVehicle] = useState(false)
  const [regYear, setRegYear] = useState('')
  const [policyInputs, setPolicyInputs] = useState([])
  const [fuelTypes, setFuelTypes] = useState([])
  const [policyType, setPolicyType] = useState('Renewal')

  // useEffect For MMV
  useEffect(() => {
    if (modal === true && activeTab != 1) {
      setActiveTab(1)
    }
  }, [modal])

  useEffect(() => {
    getVehicleRto().then(response => {
      console.log(response, 'response')
      if (response.status === true) {
        let data = response?.data
        let i = 0
        let arr = []
        while (i < data?.length) {
          let item = data[i]
          arr.push({
            label: item.registered_city_name + ' (' + item.RTO_Code + ')',
            value: item.RTO_Code,
            registered_state_name: item.registered_state_name,
            id: item.id,
            registered_city_name: item.registered_city_name
          })
          i++
        }
        setRtoData(arr)
      }
    })
    getVehiclePreviousInsurer().then(response => {
      if (response.status === true) {
        let i = 0
        let data = response.data
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            option: item.Name,
            value: item.Digit_Code
          })
          i++
        }
        setInsurerData(arr)
      }
    })
  }, [])

  console.log(fuelTypes, 'fuelTypes')

  useEffect(() => {
    if (vehicleType) {
      setVehicleType()
    }
  }, [vehicleType])

  const setVehicleType = () => {
    vehicleType === '4w'
      ? setPolicyInputs(pvtCarPolicyInfoArr)
      : vehicleType === '2w'
      ? setPolicyInputs(bikePolicyInfoArr)
      : vehicleType === 'Goods Carrying'
      ? setPolicyInputs(gcvVehiclePolicyInfoArr)
      : vehicleType === 'Passenger Carrying' &&
        setPolicyInputs(pcvVehiclePolicyInfoArr)
    setVehicleModel(vehicleType)

    getVehicleMake({
      Vehicle_Type: vehicleType === '2w' ? 'MotorBike' : 'Pvt Car'
    }).then(response => {
      if (response.status === true) {
        let data = response.data
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.Make,
            value: item.Make
          })
          i++
        }
        setMakeData(arr)
        console.log(arr, 'arrrrrrrr')
      }
    })

    dispatchQuickQuote('VehicleType', vehicleType)
    vehicleType === '2w'
      ? setQuickPick(twoWheelerMake)
      : vehicleType === '4w'
      ? setQuickPick(fourWheelerModel)
      : vehicleType === 'Passenger Carrying' && setQuickPick(fourWheelerModel)
  }

  // Format Regex for Registration number
  const formatReg = e => {
    var t = e
    t = t.replace(/-/g, '')
    var o = new Array(4)
    ;(o[0] = t.slice(0, 2)), (o[1] = ''), (o[2] = ''), (o[3] = '')
    try {
      isNaN(t.slice(2, 4))
        ? isNaN(t.slice(3, 4))
          ? (isNaN(t.slice(2, 3)) || (o[1] = t.slice(2, 3)),
            isNaN(t.slice(3, 4)) && (o[2] = t.slice(3, 4)))
          : (o[1] = t.slice(2, 3) + t.slice(3, 4))
        : (o[1] = t.slice(2, 4)),
        isNaN(t.slice(4, 8))
          ? ((o[2] = o[2] + t.slice(4, 5)),
            1 == isNaN(t.slice(5, 6)) && 1 == isNaN(t.slice(6, 7))
              ? (o[2] = o[2] + t.slice(5, 7))
              : (isNaN(t.slice(5, 6))
                  ? (o[2] = o[2] + t.slice(5, 6))
                  : (o[3] = o[3] + t.slice(5, 6)),
                isNaN(t.slice(6, 7)) || (o[3] = o[3] + t.slice(6, 7))),
            isNaN(t.slice(7, 11)) || (o[3] = o[3] + t.slice(7, 11)))
          : (o[3] = o[3] + t.slice(4, 8))
    } catch (e) {}
    return o
      .join('-')
      .replace(/ /g, '')
      .replace(/--/g, '-')
      .replace(/-\s*$/, '')
      .replace(/[^- 0-9 a-z A-z]/g, '')
  }

  // Set Registration Value
  const setRegistrationValue = e => {
    if (navigator.userAgent.match(/UCBrowser/)) return !1
    var t = document.querySelector('#vinpuut').value,
      o = e.keyCode
    if (
      -1 != navigator.userAgent.indexOf('MSIE') ||
      1 == !!document.documentMode
    ) {
      if (32 == o || 8 == o) return !1
      t.length >= 3 && (t = formatReg(t)),
        t.length >= 5 && (t = formatReg(t)),
        t.length >= 7 && (t = formatReg(t)),
        (document.querySelector('#vinpuut').value = t.toUpperCase())
    } else
      setTimeout(function () {
        if (32 == o || 8 == o) return !1
        ;(t = document.querySelector('#vinpuut').value).length >= 3 &&
          (t = formatReg(t)),
          t.length >= 5 && (t = formatReg(t)),
          t.length >= 7 && (t = formatReg(t)),
          (document.querySelector('#vinpuut').value = t.toUpperCase())
      }, 100)
    setRegistrationNumner(t)
  }

  // Submit Registration Number
  const submitRegistrationNumber = () => {
    getPolicyTenuare()
    let rtocode = RegistrationNumber.substring(0, 5)
      .replaceAll('-', '')
      .toUpperCase()
    let index = rtoData.findIndex(item => item.value === rtocode)
    dispatchQuickQuote(
      'RegistrationCity',
      rtoData[index]?.registered_city_name.toUpperCase()
    )
    dispatchQuickQuote('State', rtoData[index]?.registered_state_name)
    dispatchQuickQuote('IsVehicleNew', false)
    dispatchQuickQuote('RTOCityName', rtocode)
    setIsNewVehicle(false)
    dispatchQuickQuote('RegistrationNumber', RegistrationNumber.toUpperCase())

    // apiRequestQQ.PolicyType === 'Renewal'
    //   ? toggle(activeTab + 2)
    //   : toggle(activeTab + 1)

    policyType === 'Renewal' ? toggle(activeTab + 2) : toggle(activeTab + 1)
  }

  // Get Policy Tenure
  const getPolicyTenuare = () => {
    if (isNewVehicle === true && vehicleType == '2w') {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment()
        .add(5, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      dispatchQuickQuote('PolicyStartDate', startDate)
      dispatchQuickQuote('PolicyEndDate', endDate)
    } else if (isNewVehicle === true && vehicleType == '4w') {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment()
        .add(3, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      dispatchQuickQuote('PolicyStartDate', startDate)
      dispatchQuickQuote('PolicyEndDate', endDate)
    } else {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment()
        .add(1, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      dispatchQuickQuote('PolicyStartDate', startDate)
      dispatchQuickQuote('PolicyEndDate', endDate)
    }
  }

  // Select RTO City
  const handleSelectRtoCity = val => {
    dispatchQuickQuote('State', val?.registered_state_name)
    getPolicyTenuare()
    dispatchQuickQuote('RtoCode', val?.value)
    dispatchQuickQuote(
      'RegistrationCity',
      val?.registered_city_name.toUpperCase()
    )
    toggle(activeTab + 1)
  }

  // Select Make
  const handleSelectMake = make => {
    dispatchQuickQuote('MakeName', make)
    dispatchQuickQuote('ModelName', '')
    dispatchQuickQuote('VariantName', '')
    getVehicleModel({
      make: make,
      Vehicle_Type: vehicleModel === '2w' ? 'MotorBike' : 'Pvt Car'
    }).then(response => {
      if (response.status === true) {
        toggle(activeTab + 1)
        let data = response.data
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.Model,
            value: item.Model
          })
          i++
        }
        setModelData(arr)
      }
    })
  }

  // Select Model
  const handleSelectModel = model => {
    dispatchQuickQuote('ModelName', model)
    dispatchQuickQuote('VariantName', '')
    getVehicleVariant({
      make: apiRequestQQ.MakeName,
      model: model,
      Vehicle_Type: vehicleModel === '2w' ? 'MotorBike' : 'Pvt Car'
    }).then(response => {
      if (response.status === true) {
        let data = response.data
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex(v2 => v2.Fuel_Type === v.Fuel_Type) === i
        )
        let j = 0
        let fuelarr = []
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].Fuel_Type)
          j++
        }
        setFuelTypes(fuelarr)
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.Variant,
            value: item.Vehicle_Code,
            Fuel_Type: item.Fuel_Type,
            Cubic_Capacity: item.Cubic_Capacity,
            Seating_Capacity: item.Seating_Capacity,
            HDFC: item.HDFC,
            Shriram: item.Shriram,
            Kotak: item.Kotak,
            Reliance: item.Reliance,
            Future: item.Future,
            Royal: item.Royal,
            Bajaj: item.Bajaj,
            vehicleId: item.id,
            Liberty: item.Liberty
          })
          i++
        }
        setVariantData(arr)

        toggle(activeTab + 1)
      }
    })
  }

  // Select Fuel Type
  const handleSelectPetrolType = val => {
    if (val == 'CNG' || val == 'LPG') {
      dispatchQuickQuote('IsInternalCNG', true)
    }
    dispatchQuickQuote('FuelType', val)
    toggle(activeTab + 1)
  }

  // Select Varient Type
  const handleSelectVariant = variant => {
    dispatchQuickQuote('VariantCode.Digit', variant.value)
    dispatchQuickQuote('VariantCode.HDFC', variant.HDFC)
    dispatchQuickQuote('VariantCode.Shriram', variant.Shriram)
    dispatchQuickQuote('VariantCode.Kotak', variant.Kotak)
    dispatchQuickQuote('VariantCode.Reliance', variant.Reliance)
    dispatchQuickQuote('VariantCode.Liberty', variant.Liberty)
    dispatchQuickQuote('VariantCode.Future', variant.Future)
    dispatchQuickQuote('VariantCode.Royal', variant.Royal)
    dispatchQuickQuote('VariantCode.Bajaj', variant.Bajaj)
    dispatchQuickQuote('VariantCode.vehicleId', variant.vehicleId)
    // let postdata = {
    //   VehicleType: apiRequestQQ.VehicleType,
    //   Make: apiRequestQQ.MakeName,
    //   Model: apiRequestQQ.ModelName,
    //   Variant: variant.label,
    //   CC: variant.Cubic_Capacity,
    //   fuelType: variant.Fuel_Type,
    // };

    dispatchQuickQuote('FuelType', variant.Fuel_Type)
    dispatchQuickQuote('VariantName', variant.label)
    dispatchQuickQuote('CarryingCapacity', variant.Seating_Capacity)
    dispatchQuickQuote('CubicCapacity', variant.Cubic_Capacity)
    // getBajajMMV(postdata).then((response) => {
    //   if (response.status === true) {
    //     if (response?.data) {
    //       dispatchQuickQuote("VariantCode.Bajaj", response.data.vehiclecode);
    //       dispatchQuickQuote("VehicleMakeCode", response.data.vehiclemakecode);
    //       dispatchQuickQuote("VehicleModelCode", response.data.vehiclemodelcode);
    //       dispatchQuickQuote("VehicleSubTypeCode", response.data.vehiclesubtypecode);
    //       dispatchQuickQuote("CarryingCapacity", response.data.carryingcapacity);
    //       dispatchQuickQuote("CubicCapacity", response.data.cubiccapacity);
    //       // dispatchQuickQuote("VariantName", response.data.vehiclesubtype);
    //     } else {
    //       dispatchQuickQuote("VariantName", variant.label);
    //     }
    //   } else {
    //     dispatchQuickQuote("VariantName", variant.label);
    //   }
    // });
    toggle(activeTab + 1)
  }

  // Select Registration Year
  const selectVehicleYear = year => {
    setRegYear(year)
    dispatchQuickQuote('RegistrationYear', year)
    toggle(activeTab + 1)
  }

  const checkIfDateIsIn3Month = dateToCheck => {
    return (
      moment().diff(dateToCheck, 'days') > -90 &&
      moment().diff(dateToCheck, 'days') <= 0
    )
  }

  // Submit Policy Details
  const submitPolicyDetails = data => {
    let newData = { ...apiRequestQQ }
    if (
      checkIfDateIsIn3Month(data.PrePolicyEndDate) &&
      apiRequestQQ.IsVehicleNew === false &&
      apiRequestQQ.PolicyStatus == 'continue'
    ) {
      const startDate = moment(data.PrePolicyEndDate)
        .add(1, 'day')
        .format('YYYY-MM-DD')
      const endDate = moment(startDate, 'YYYY-MM-DD')
        .add(1, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      dispatchQuickQuote('PolicyStartDate', startDate)
      dispatchQuickQuote('PolicyEndDate', endDate)
      newData.PolicyStartDate = startDate
      newData.PolicyEndDate = endDate
    } else if (apiRequestQQ.IsVehicleNew === false) {
      const startDate = moment().add(1, 'day').format('YYYY-MM-DD')
      const endDate = moment(startDate, 'YYYY-MM-DD')
        .add(1, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      dispatchQuickQuote('PolicyStartDate', startDate)
      dispatchQuickQuote('PolicyEndDate', endDate)
      newData.PolicyStartDate = startDate
      newData.PolicyEndDate = endDate
    }
    for (let key in data) {
      if (Object.keys(apiRequestQQ).includes(key)) {
        if (key === 'PolicyStatus' && data.PolicyStatus == 'false') {
          dispatchQuickQuote('IsPreviousInsurerKnown', false)
          dispatchQuickQuote('PolicyStatus', 'continue')
        }
        newData[key] = data[key]
        dispatchQuickQuote(key, data[key])
      }
    }

    setTimeout(() => {
      savePolicyToDatabase(newData)
      // debugger

      // Digit
      digitAPICall(newData)

      // Bajaj
      if (
        moment().format('YYYY') - apiRequestQQ.RegistrationYear <= 15 &&
        newData.PolicyStatus == 'continue'
      ) {
        bajajApiCall(newData)
      }

      // ShriRam
      shriramGenerateProposal(newData, false)

      // Kotak
      if (newData.PreInsurerCode != '152') {
        KotakAPICall(newData)
      }

      // Reliance
      if (
        moment().format('YYYY') - apiRequestQQ.RegistrationYear <= 15 &&
        (apiRequestQQ.NewPolicyType != 'ThirdParty' ||
          newData.PolicyStatus == 'continue')
      ) {
        RelianceAPICall(newData)
      }

      // Future General
      FutureGeneralQuickQuote(newData)

      // Royal Sundaram
      RoyalSundramAPICall(newData)

      // HDFC
      if (
        moment().format('YYYY') - apiRequestQQ.RegistrationYear <= 15 &&
        newData.PolicyStatus == 'continue'
      ) {
        let HDFCData = newData
        HDFCData.AddOns.PersonalAccident = true
        HDFCCalculatePremium(newData)
      }

      // ZUNO
      getZunoQuickQuote(newData)

      // Liberty
      getLibertyAPICall(newData)

      navigate('/quotation')
    }, 2000)
  }

  // Submit User Details
  const submitUserDetails = data => {
    let newData = {
      FirstName: data.fullname.split(' ')[0],
      LastName: data.fullname.split(' ')[1],
      Email: data.Email,
      MobileNumber: data.MobileNumber
    }

    for (let key in newData) {
      dispatchQuickQuote(key, newData[key])
    }
    let postData = {
      name: newData.FirstName + '' + newData.LastName,
      email: newData.Email,
      phone: newData.MobileNumber
    }

    toggle(activeTab + 1)
    createCustomer(postData)
  }

  return (
    <div className='hero-text'>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          {vehicleType === '2w' ? (
            <h1>
              Buy Your Two-Wheeler Insurance In <br />
              <span>60 Seconds!</span>
            </h1>
          ) : (
            <h1 className='headline' style={{ lineHeight: 1.5 }}>
              Compare & Save <span>upto 85%* on</span>
              <br /> {vehicleType} Insurance <br />
            </h1>
          )}
          <div className='radio-btn-wrapper'>
            <input
              type='radio'
              id='renew'
              name='policy-type'
              onChange={() => setPolicyType('Renewal')}
              value='renew'
              className='radio-buttons'
            />
            <label
              htmlFor='renew'
              className='radio-label'
              style={{ marginLeft: 10 }}
            >
              Renew
            </label>
            <input
              type='radio'
              id='new'
              name='policy-type'
              onChange={() => setPolicyType('New')}
              value='new'
              className='radio-buttons'
              style={{ marginLeft: 20 }}
            />
            <label
              htmlFor='new'
              className='radio-label'
              style={{ marginLeft: 10 }}
            >
              New
            </label>
          </div>

          <div className='input-frm'>
            <form action='' className='form-hero'>
              {policyType === 'Renewal' ? (
                <input
                  type='text'
                  placeholder='Enter Registration number'
                  id='vinpuut'
                  onChange={e => setRegistrationValue(e)}
                  className='styled-input'
                />
              ) : (
                ''
              )}

              <br />
              <button
                className='styled-btn'
                onClick={e => {
                  e.preventDefault()
                  submitRegistrationNumber()
                  // toggle(activeTab + 1)
                }}
              >
                {policyType === 'Renewal' ? 'Get Details' : 'Select RTO Code'}
              </button>
            </form>
          </div>
        </TabPane>

        <TabPane tabId={2}>
          <p className='styled-headline'>Select City /RTO</p>
          {/* <input
            type='text'
            className='styled-input'
            placeholder='Search RTO'
          /> */}
          <ReactSelect
            options={rtoData}
            name='rto'
            placeholder='Select RTO Location'
            onChange={val => handleSelectRtoCity(val)}
          />
          <p className='styled-sub-headline'>Select City</p>
          <div
            className='radio-btn-wrapper'
            style={{
              minWidth: 200
              // marginTop: 20,
            }}
          >
            {/* {cities.map(res => (
              <div
                style={{ flex: '50%', marginBottom: 12 }}
                className='linear-flex'
              >
                <input type='radio' />
                <label className='radio-label'>{res?.name}</label>
              </div>
            ))} */}
          </div>
          <div>
            {/* <button className='styled-card'>DL1S</button> */}
            <div className='col-lg-11 col-12 chooseinput'>
              {rtoData &&
                rtoData.length > 0 &&
                rtoData
                  .filter(item => item.registered_state_name === 'RAJASTHAN')

                  .slice(0, 20)
                  .map((item, index) => (
                    <>
                      <input
                        type='radio'
                        className='form-control d-none'
                        id={'rto' + item.id}
                        name='regcitycode'
                        onClick={() => handleSelectRtoCity(item)}
                      />
                      <label htmlFor={'rto' + item.id} className='shadow'>
                        <span>{item.value}</span>
                        {/* <i className='fas fa-check-circle text-success' /> */}
                      </label>
                    </>
                  ))}
            </div>
          </div>
          <button className='styled-btn' onClick={() => toggle(activeTab + 1)}>
            NEXT
          </button>
        </TabPane>
        <TabPane tabId={3}>
          <p className='styled-headline'>Select The Wheeler Manufacture</p>
          {/* <input
            type='text'
            className='styled-input'
            placeholder='Search Manufacture'
          /> */}

          <div className='position-relative mt-5'>
            <ReactSelect
              options={makeData}
              name='make'
              placeholder='Select Manufacturer'
              onChange={val => handleSelectMake(val.value)}
            />
          </div>
          <p className='styled-sub-headline'>Popular Manufacturers</p>
          <div className='brand-card-wrapper'>
            {/* {brands.map(res => (
              <button className='styled-card' style={{ margin: 5 }}>
                Hero
              </button>
            ))} */}
          </div>
          <br />
          <button className='styled-btn' onClick={() => toggle(activeTab + 1)}>
            Next
          </button>
        </TabPane>

        <TabPane tabId={4}>
          <p className='styled-headline'>Select Two Wheeler Modal</p>
          {/* <input
            type='text'
            className='styled-input'
            placeholder='Search Modal'
          /> */}

          <ReactSelect
            options={modelData}
            name='model'
            placeholder='Select vehicle modal'
            onChange={val => handleSelectModel(val.value)}
          />
          <p className='styled-sub-headline'>Popular Models</p>
          <div className='flex-wrap-div'>
            {modelData &&
              modelData.length > 0 &&
              modelData.slice(0, 20).map((item, index) => (
                <>
                  <input
                    type='radio'
                    onClick={() => handleSelectModel(item.value)}
                    className='form-control d-none'
                    id={'rto11' + index}
                    name='carComanY'
                  />
                  <label htmlFor={'rto11' + index} className='shadow'>
                    <span>{item.value}</span>
                  </label>
                </>
              ))}
          </div>
          {/* <p className='styled-sub-headline'>Other Models</p>
          <div className='flex-wrap-div'>
            {otherModels.map(res => (
              <div className='linear-flex'>
                <input type='radio' />
                <label>{res.name}</label>
              </div>
            ))}
          </div> */}
          <button className='styled-btn' onClick={() => toggle(activeTab + 1)}>
            Next
          </button>
        </TabPane>

        <TabPane tabId={5}>
          <div className='mmvcard h-100 p-lg-5 p-4 pe-lg-0 pe-4'>
            <div className='mmvcard__content h-100 d-flex flex-column justify-content-between align-items-start'>
              <div className='d-flex justify-content-between align-items-center pe-lg-5 w-100 mt-lg-3 mt-5'>
                <p className='fs-1 fw-bold'>Select Your Fuel Type?</p>
              </div>

              <div className='car-company d-flex flex-wrap justify-content-lg-start justify-content-center mt-5 gen-info'>
                {fuelTypes.includes('Petrol') && (
                  <>
                    <input
                      type='radio'
                      onClick={() => handleSelectPetrolType('Petrol')}
                      className='form-control d-none'
                      id={'rto11petrol'}
                      name='carComanY'
                    />
                    <label htmlFor={'rto11petrol'} className='shadow'>
                      <span>Petrol</span>
                      {/* <i className="fas fa-check-circle text-success" /> */}
                    </label>
                  </>
                )}
                {fuelTypes.includes('Diesel') && (
                  <>
                    <input
                      type='radio'
                      onClick={() => handleSelectPetrolType('Diesel')}
                      className='form-control d-none'
                      id={'rto11diesal'}
                      name='carComanY'
                    />
                    <label htmlFor={'rto11diesal'} className='shadow'>
                      <span>Diesel</span>
                      {/* <i className="fas fa-check-circle text-success" /> */}
                    </label>
                  </>
                )}
                {fuelTypes.includes('Battery') && (
                  <>
                    <input
                      type='radio'
                      onClick={() => handleSelectPetrolType('Battery')}
                      className='form-control d-none'
                      id={'rto11ele'}
                      name='carComanY'
                    />
                    <label htmlFor={'rto11ele'} className='shadow'>
                      <span>Electric</span>
                      {/* <i className="fas fa-check-circle text-success" /> */}
                    </label>
                  </>
                )}
                {fuelTypes.includes('CNG') && (
                  <>
                    <input
                      type='radio'
                      onClick={() => handleSelectPetrolType('CNG')}
                      className='form-control d-none'
                      id={'rto11cng'}
                      name='carComanY'
                    />
                    <label htmlFor={'rto11cng'} className='shadow'>
                      <span>CNG</span>
                    </label>
                  </>
                )}
                {fuelTypes.includes('LPG') && (
                  <>
                    <input
                      type='radio'
                      onClick={() => handleSelectPetrolType('LPG')}
                      className='form-control d-none'
                      id={'rto11lpg'}
                      name='carComanY'
                    />
                    <label htmlFor={'rto11lpg'} className='shadow'>
                      <span>LPG</span>
                    </label>
                  </>
                )}
              </div>
            </div>
          </div>
        </TabPane>

        <TabPane tabId={6}>
          <p className='styled-headline'>Select Two Wheeler Varient</p>
          {/* <input
            type='text'
            className='styled-input'
            placeholder='Search Modal'
          /> */}

          <ReactSelect
            options={variantData.filter(
              item => item.Fuel_Type == apiRequestQQ.FuelType
            )}
            name='model'
            placeholder='Select vehicle varient'
            onChange={val => handleSelectVariant(val)}
          />

          <div className='flex-wrap-div'>
            {variantData &&
              variantData.length > 0 &&
              variantData
                .filter(item => item.Fuel_Type == apiRequestQQ.FuelType)
                .slice(0, 20)
                .map((item, index) => (
                  <>
                    <input
                      type='radio'
                      className='form-control d-none'
                      id={'rot12' + index}
                      name='carComanY11'
                    />
                    <label
                      htmlFor={'rot12' + index}
                      onClick={() => handleSelectVariant(item)}
                      value={item.value}
                      className='shadow'
                    >
                      <span>{item.label}</span>
                      {/* <i className="fas fa-check-circle text-success" /> */}
                    </label>
                  </>
                ))}
          </div>
          <button className='styled-btn' onClick={() => toggle(activeTab + 1)}>
            Next
          </button>
        </TabPane>

        <TabPane tabId={7}>
          <p className='styled-headline'>Select Registraion Year</p>

          <div className='flex-wrap-div'>
            {generateArrayOfYears()
              .filter((item, i) => {
                if (isNewVehicle === true) {
                  return i < 1
                } else {
                  return i > 0
                }
              })
              .map((item, index) => (
                <>
                  <input
                    type='radio'
                    className='form-control d-none'
                    onClick={() => selectVehicleYear(item)}
                    value={item}
                    id={'yrr' + index}
                    name='CArYear'
                  />
                  <label htmlFor={'yrr' + index} className='shadow'>
                    <span>{item}</span>
                    {/* <i className="fas fa-check-circle text-success" /> */}
                  </label>
                </>
              ))}
          </div>
          <button className='styled-btn' onClick={() => toggle(activeTab + 1)}>
            Next
          </button>
        </TabPane>

        {/* <TabPane tabId={8}>
          <div>
            <p className='styled-sub-headline'>Your Vehicle Details</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: 360
              }}
            >
              <input
                className='styled-input'
                style={{ flex: '40%', marginRight: 10 }}
                value={apiRequestQQ?.RtoCode}
              />
              <input
                className='styled-input'
                style={{ flex: '40%', marginLeft: 10 }}
                value={apiRequestQQ?.MakeName}
              />
            </div>
            <input className='styled-input' value={apiRequestQQ?.ModelName} />
            <input className='styled-input' value={apiRequestQQ?.VariantName}/>
            <input className='styled-input' value={apiRequestQQ?.RegistrationYear}/>
            <br />
            <button
              className='styled-btn'
              style={{ width: '100%', maxWidth: 360 }}

            >
              View Plans
            </button>
          </div>
        </TabPane> */}

        <TabPane tabId={8}>
          <form onSubmit={handleSubmit(submitUserDetails)}>
            <div className='mmvcard h-100 fineladdition'>
              <div className='mmvcard__content h-100 d-flex flex-column justify-content-between align-items-center'>
                <div className='row mx-0 px-0 text-center justify-content-between align-items-center h-100 w-100'>
                  <div className='col-lg-5 col-12 ps-lg-0'>
                    <div className='mmvcard__img'>
                      <img
                        src={
                          vehicleType == '4w'
                            ? '/assets/images/insuasperreqcar.png'
                            : vehicleType == '2w'
                            ? '/assets/images/insuasperreqbike.png'
                            : vehicleType == 'pcv'
                            ? '/assets/images/insuasperreqpcv.png'
                            : vehicleType == 'gcv' &&
                              '/assets/images/insuasperreqgcv.png'
                        }
                        className='w-100 carmodalimg ps-lg-5'
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='col-lg-7 col-12'>
                    <div className='container-fluid p-lg-5'>
                      <aside className='title text-start mt-lg-0 mt-3'>
                        <span className='herotitle mb-0'>
                          "Fill Your Personal Details"
                        </span>
                      </aside>

                      <p className='fs-2 m-0 text-start'>
                        (Just one more step for your prefered quote)
                      </p>

                      <div className='vehicleInputs mt-5 mb-lg-0 mb-5'>
                        <div className='form-floating text-start'>
                          <input
                            type='text'
                            className='form-control'
                            {...register('fullname')}
                            id='mname'
                            placeholder='Enter Name'
                          />
                          <label htmlFor='mname'>Enter First Name</label>

                          {/* <p className="m-0 f-error">Input invalid</p> */}
                        </div>

                        <div className='form-floating text-start my-5'>
                          <input
                            type='text'
                            {...register('Email', {
                              required: 'Email is required',
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Invalid email address'
                              }
                            })}
                            className='form-control'
                            id='emailuser'
                            placeholder='Enter Name'
                          />
                          <label htmlFor='emailuser'>Enter Email</label>

                          {/* <p className="m-0 f-error">{errors?.Email?.message}</p> */}

                          <p className='m-0 f-error'>
                            {errors?.Email?.message}
                          </p>
                        </div>
                        <div className='form-floating text-start'>
                          <input
                            type='text'
                            {...register('MobileNumber', {
                              required: 'Mobile is required',
                              pattern: {
                                value:
                                  /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                                message: 'Invalid Mobile Number'
                              }
                            })}
                            className='form-control mt-5'
                            id='mmobie'
                            placeholder='Mobile Number'
                            maxLength='10'
                          />
                          <label htmlFor='mmobie'>Mobile Number</label>
                          <p className='m-0 f-error'>
                            {errors?.MobileNumber?.message}
                          </p>
                        </div>

                        <div className='form-group form-check d-block position-relative  mt-5 mb-4 text-start'>
                          <input
                            type='checkbox'
                            className='form-check-input d-none'
                            id='day'
                          />
                          {/* <label
                            className='form-check-label ps-4 checkboxlabel mt-2 fs-3'
                            htmlFor='day'
                          >
                            Check this to accept
                            <Link
                              to={'/privacyandcondition'}
                              href='#'
                              className='mx-2 fw-bold d-inline-block'
                            >
                              terms and conditions
                            </Link>
                            and
                            <Link
                              to={'/privacyandcondition'}
                              href='#'
                              className='mx-2 fw-bold d-inline-block'
                            >
                              privacy policy
                            </Link>
                          </label> */}
                        </div>

                        <div className='text-start mt-5'>
                          <button
                            type='submit'
                            className='btn btn-primary get-quotebtn d-inline-flex position-relative px-5 py-3 text-start fs-3'
                          >
                            Get Your Quote
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </TabPane>

        <TabPane tabId={9}>
          <div className='mmvcard h-100 p-lg-5 p-4 pe-lg-0 pe-4'>
            <div className='mmvcard__content h-100 d-flex flex-column justify-content-between align-items-start'>
              <QuotePolicyDetails
                regYear={regYear}
                isNewVehicle={isNewVehicle}
                insurerData={insurerData}
                submitPolicyDetails={data => submitPolicyDetails(data)}
              />
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default FormTabs

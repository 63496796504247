import React, { useEffect, useState, useNa } from 'react'
import './motorQuotation.css'
import Header from '../../common/Header'
import Layout from '../../common/Layout'
import { useSelector } from 'react-redux'
import { useNavigate, useNavigation } from 'react-router-dom'

const MotorQuotation = () => {
  const QuickQouteResult = useSelector(state => state?.root?.QuickQouteResult)
  const apiRequestQQ = useSelector(state => state?.root?.apiRequestQQ)
  const navigate = useNavigate()

  const handleNavigation = () => {
    navigate('/proposal')
  }

  return (
    // <div>
    <Layout>
      <div className='quotaion-container'>
        <section className='left-section'>
          <div className='no-shadow-card'>
            <div className='linear-div' style={{ marginTop: 10 }}>
              <p className='semibold-text'>Your Vehicle Details</p>
              <p className='edit-btn'>Edit</p>
            </div>
            <div className='line'></div>
            <div
              className='linear-div'
              style={{
                justifyContent: 'flex-start'
              }}
            >
              <img src='./assets/img/bike.png' height={29} width={42} />
              <p className='small-text' style={{ marginLeft: 10 }}>
                Vehicle <br />
                <p style={{ marginTop: 0 }} className='semibold-text'>
                  {apiRequestQQ?.MakeName} {apiRequestQQ?.ModelName}
                </p>
              </p>
            </div>
            <div className='linear-div'>
              <p className='small-text'>
                Year <br />{' '}
                <p className='semibold-text'>
                  {apiRequestQQ?.RegistrationYear}
                </p>
              </p>
              <p className='small-text'>
                RTO <br />{' '}
                <p className='semibold-text'>
                  {apiRequestQQ?.RTOCityName} ({apiRequestQQ?.RegistrationCity})
                </p>
              </p>
            </div>
          </div>

          <div className='no-shadow-card'>
            <div className='linear-div'>
              <p className='semibold-text'>Insured value (IDV)</p>{' '}
              <p className='edit-btn'>Edit</p>
            </div>
            <div className='line'></div>
            <p style={{ margin: 10, fontSize: 14, fontWeight: 300 }}>
              Currently Set For Lowest Price: <strong>22,522</strong>
            </p>
          </div>

          <div className='no-shadow-card'>
            <div className='linear-div'>
              <p className='semibold-text'>No claim bonus</p>
            </div>
            <div className='line'></div>
            <p
              style={{
                margin: 10,
                fontSize: 14,
                fontWeight: 300,
                lineHeight: 1.8
              }}
            >
              Currently applicable NCB: N/A <br />
              Previous Year NCB:N/A <br />
              Claims in last policy: N/A
            </p>
          </div>

          <div className='no-shadow-card'>
            <p className='semibold-text' style={{ margin: 10 }}>
              Plan Duration: 1 year
            </p>
          </div>
        </section>

        <section className='main-section'>
          <div className='input-wrapper'>
            <input
              type='text'
              className='styled-input'
              style={{ width: 269 }}
              placeholder='Bike IDV'
            />
            <input
              type='text'
              className='styled-input'
              style={{ width: 269 }}
              placeholder='Bike IDV'
            />
            <input
              type='text'
              className='styled-input'
              style={{ width: 269 }}
              placeholder='Bike IDV'
            />
            <div
              className='shadow-card'
              style={{ width: 409, justifyContent: 'center' }}
            >
              <p className='button-txt'>Bike IDV</p>
            </div>
            <div
              className='shadow-card'
              style={{ width: 409, justifyContent: 'center' }}
            >
              <p className='button-txt'>Third Party Cover</p>
            </div>
          </div>

          {/* <TextField id="i" label="Bike IDV" variant="outlined" /> */}
          <p className='headline'>6 Comprehensive Plans</p>
          <p className='sub-headline'>
            Covers Damages To Your Vehicle And Third-Party
          </p>
          {QuickQouteResult &&
            QuickQouteResult.length > 0 &&
            QuickQouteResult.map((item, index) => (
              <div className='shadow-card' onClick={() => handleNavigation()}>
                <img
                  src={item?.Logo}
                  width='100px'
                  style={{ border: 'none', borderRadius: '5px' }}
                />
                <p className='semibold-text' style={{ textAlign: 'center' }}>
                  IDV <br /> <strong>{item?.idv}</strong>
                </p>
                <p className='semibold-text' style={{ textAlign: 'center' }}>
                  Claims Setteled <br /> <strong>{item?.claims}</strong>
                </p>
                <div className='price-div'>
                  <p className='price-txt'>{item?.FinalPremium}</p>
                </div>
              </div>
            ))}
        </section>
      </div>
    </Layout>
  )
}

export default MotorQuotation

import React, { useState } from "react";
import "./blogsDetails.css";
import SubHeader from "../../common/subHeader/SubHeader";
import Header from "../../common/Header";
import Layout from "../../common/Layout";
function BlogDetails({ img, caption, content }) {
  const [recentPosts, setRecentPosts] = useState([
    {
      id: 1,
      name: "Understanding and Addressing Teen Depression and Recognizing Its Danger",
    },
    {
      id: 2,
      name: "9 Helpful Tips for parents to help your child beat exam stress",
    },
    {
      id: 3,
      name: "Ear infection- symptoms, Causes & Treatment",
    },
    {
      id: 4,
      name: "10 Natural Ways to Deworm Your Body Without Chemicals",
    },
    {
      id: 5,
      name: "Top 10 Vitamin A Foods That Helps to Boost Your Immunity",
    },
  ]);
  return (
    <Layout>
      <SubHeader headline={"Blog Details"} subHeadline={"Blog"} />
      <div style={{ display: "flex" }}>
        <section className="blog-details-sec">
          <img src="/assets/img/Rectangle 101.png" width={"100%"} />

          <p className="blog-title">
            Understanding and Addressing Teen Depression and Recognizing Its
            Danger
          </p>
          <div className="linear-div" style={{ marginLeft: 0 }}>
            <img src="/assets/img/admin.png" height={13} width={11} />
            <span className="small-txt">POSTED BY ADMIN</span>
          </div>
          <p className="content">
            Depression, often described as a “silent killer,” is a prevalent
            mental health disorder that affects millions of individuals
            worldwide. It transcends cultural, economic, and social boundaries,
            impacting people of all ages and backgrounds. While occasional
            sadness or feeling down is normal, depression is different. It is a
            complex illness that goes far beyond temporary emotional
            fluctuations.
          </p>
        </section>

        <section className="recent-posts-sec">
          <p className="recent-posts-title">Recent Posts</p>
          {recentPosts.map((res) => (
            <ul>
              <li className="recent-post-txt">{res.name}</li>
            </ul>
          ))}
        </section>
      </div>
    </Layout>
  );
}

export default BlogDetails;

import React, { useState } from "react";
import SubHeader from "../../common/subHeader/SubHeader";
import Header from "../../common/Header";
import "./blogs.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../common/Layout";

function Blogs() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([
    {
      id: 1,
      img: "/assets/img/Rectangle 101.png",
      caption:
        "Understanding and Addressing Teen Depression and Recognizing Its Danger",
    },
    {
      id: 2,
      img: "/assets/img/Rectangle 101.png",
      caption:
        "Understanding and Addressing Teen Depression and Recognizing Its Danger",
    },
    {
      id: 3,
      img: "/assets/img/Rectangle 101.png",
      caption:
        "Understanding and Addressing Teen Depression and Recognizing Its Danger",
    },
    {
      id: 4,
      img: "/assets/img/Rectangle 101.png",
      caption:
        "Understanding and Addressing Teen Depression and Recognizing Its Danger",
    },
    {
      id: 5,
      img: "/assets/img/Rectangle 101.png",
      caption:
        "Understanding and Addressing Teen Depression and Recognizing Its Danger",
    },
    {
      id: 6,
      img: "/assets/img/Rectangle 101.png",
      caption:
        "Understanding and Addressing Teen Depression and Recognizing Its Danger",
    },
    {
      id: 7,
      img: "/assets/img/Rectangle 101.png",
      caption:
        "Understanding and Addressing Teen Depression and Recognizing Its Danger",
    },
  ]);

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
        }}
      >
        <SubHeader headline={"Blog"} subHeadline={"Blog"} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: "100%",
            justifyContent: "center",
          }}
        >
          <div className="blogs-container">
            {blogs.map((res) => (
              <div
                className="blog-card"
                onClick={() => navigate("/blog-details")}
              >
                <img src={res.img} height={200} width={"100%"} />
                <div className="linear-div">
                  <img src="/assets/img/admin.png" height={13} width={11} />
                  <span className="small-txt">POSTED BY ADMIN</span>
                </div>
                <p className="caption">{res.caption}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Blogs;

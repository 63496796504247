import React from "react";

function SubHeader({ headline, subHeadline }) {
  return (
    <div className="sub-header" style={{ width: "100%" }}>
      <p className="headline">{headline}</p>
      <span className="sub-headline">
        Home - <span style={{ color: "#00B0CB" }}>{subHeadline}</span>
      </span>
    </div>
  );
}

export default SubHeader;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
const Layout = ({ children, pageClass, footer }) => {
  return (
    <main className={pageClass}>
      <Header />
      {children}
      {footer != false && <Footer />}
    </main>
  );
};

export default Layout;

import moment from "moment";

export const twoWheelerMake = [
  {
    image: "assets/images/logos/bikes/hero.png",
    value: "HERO MOTOCORP",
  },
  {
    image: "assets/images/logos/bikes/honda.png",
    value: "HONDA",
  },
  {
    image: "assets/images/logos/bikes/bajaj.png",
    value: "BAJAJ",
  },
  {
    image: "assets/images/logos/bikes/tvs.png",
    value: "TVS",
  },
  {
    image: "assets/images/logos/bikes/ROYAL_ENFIELD.png",
    value: "ROYAL ENFIELD",
  },
  {
    image: "assets/images/logos/bikes/MAHINDRA.png",
    value: "MAHINDRA AND MAHINDRA",
  },
  {
    image: "assets/images/logos/bikes/HEROHONDA.png",
    value: "HERO HONDA",
  },
  {
    image: "assets/images/logos/bikes/yamaha.png",
    value: "YAMAHA",
  },
  {
    image: "assets/images/logos/bikes/KTM.png",
    value: "KTM",
  },
  {
    image: "assets/images/logos/bikes/jawa.png",
    value: "JAWA",
  },
  {
    image: "assets/images/logos/bikes/lml.png",
    value: "LML",
  },
  {
    image: "assets/images/logos/bikes/HYOSUNG.png",
    value: "HYOSUNG",
  },
];

export const fourWheelerModel = [
  {
    image: "assets/images/logos/cars/suzuki.png",
    value: "MARUTI SUZUKI",
  },
  {
    image: "assets/images/logos/cars/tata.png",
    value: "TATA",
  },
  {
    image: "assets/images/logos/cars/honda.png",
    value: "HONDA",
  },
  {
    image: "assets/images/logos/cars/ford.png",
    value: "FORD",
  },
  {
    image: "assets/images/logos/cars/hyundai.png",
    value: "HYUNDAI",
  },
  {
    image: "assets/images/logos/cars/kia.png",
    value: "KIA",
  },
  {
    image: "assets/images/logos/cars/mahindra.png",
    value: "MAHINDRA AND MAHINDRA",
  },
  {
    image: "assets/images/logos/cars/mg.png",
    value: "MORRIS GARAGES",
  },
  {
    image: "assets/images/logos/cars/nissan.png",
    value: "NISSAN MOTORS",
  },
  {
    image: "assets/images/logos/cars/renault.png",
    value: "RENAULT",
  },
  {
    image: "assets/images/logos/cars/skoda.png",
    value: "SKODA",
  },
  {
    image: "assets/images/logos/cars/toyota.png",
    value: "TOYOTA",
  },
  {
    image: "assets/images/logos/cars/wv.png",
    value: "VOLKSWAGEN",
  },
  {
    image: "assets/images/logos/cars/volvo.png",
    value: "VOLVO",
  },
  {
    image: "assets/images/logos/cars/audi.png",
    value: "AUDI",
  },
  {
    image: "assets/images/logos/cars/bmw.png",
    value: "BMW",
  },
];

export const pvtCarPolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "StandAlone OD" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Vehicle Owned By",
    name: "CustomerType",
    type: "select",
    data: [
      { value: "INDIVIDUAL", option: "Individual" },
      { value: "COMPANY", option: "Organization" },
    ],
    validation: {
      required: false,
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "select",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },
];

export const bikePolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "StandAlone OD" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Vehicle Owned By",
    name: "CustomerType",
    type: "select",
    data: [
      { value: "INDIVIDUAL", option: "Individual" },
      { value: "COMPANY", option: "Organization" },
    ],
    validation: {
      required: false,
    },
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "select",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },
];

export const pcvVehiclePolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "StandAlone OD" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "radio",
    option1: "Yes",
    option2: "No",
    value1: "Yes",
    value2: "No",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },

  {
    label: "Number Of Wheels",
    name: "number_of_wheels",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Seating Capacity",
    name: "seating_capacity",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
];

export const gcvVehiclePolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "StandAlone OD" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: false,
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: false,
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Vehicle Owned By",
    name: "CustomerType",
    type: "select",
    data: [
      { value: "INDIVIDUAL", option: "Individual" },
      { value: "COMPANY", option: "Organization" },
    ],
    validation: {
      required: false,
    },
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "select",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },

  {
    label: "Number Of Wheels",
    name: "number_of_wheels",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Gross Weight",
    name: "seating_capacity",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Carrier Type",
    name: "carrier_type",
    type: "select",
    placeholder: "Carrier Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Public", option: "Puclic" },
      { value: "Private", option: "Private" },
    ],
  },
];

export const Documents = [
  {
    id: 1,
    option: "RC-Front",
    name: "rc_front",
    type: "file",
  },
  {
    id: 2,
    option: "RC-Back",
    name: "rc_back",
    type: "file",
  },
  {
    id: 3,
    option: "Insurance-01",
    name: "insurance_01",
    type: "file",
  },
  {
    id: 4,
    option: "Insurance-02",
    name: "insurance_02",
    type: "file",
  },
  {
    id: 5,
    option: "29/30 Sales Latter",
    name: "sales_letter",
    type: "file",
  },
];

export const policyResultArr = (
  Logo,
  Api_name,
  PolicyId,
  ApiId,
  RegisterNumber,
  Make,
  Model,
  variant,
  BasicODPremium,
  BasicTPPremium,
  PAForOwner,
  NetPremium,
  GST,
  FinalPremium,
  MinIdv,
  MaxIdv,
  idv,
  StartDate,
  EndDate,
  discount,
  insurer,
  policyType,
  RoadSideAssistance,
  EngineProtection,
  TyreProtection,
  RimProtection,
  Consumables,
  IsElectricalAccessories,
  IsNonElectricalAccessories,
  InvoiceCover,
  EngineGearBox,
  NCBProtection,
  VoluntaryDeductive,
  PassengerCover,
  LossOfPersonalBelongings,
  ZeroDepriciationCover,
  KeyReplacement,
  PaCoverAmount,
  PassengerCoverAmount,
  ElectricAmount,
  nonElectricalAmount,
  VoluntaryAmount,
  NCBDiscount,
  CNGValue,
  DriverLiability
) => {
  let start = moment(StartDate).format("yyyy");
  let end = moment(EndDate).format("yyyy");
  return {
    Logo: Logo,
    Api_name: Api_name,
    ApiId: ApiId,
    PolicyId: PolicyId,
    RegisterNumber: RegisterNumber,
    Make: Make,
    Model: Model,
    Variant: variant,
    BasicODPremium: BasicODPremium,
    BasicTPPremium: BasicTPPremium,
    PersonalAccident: PAForOwner,
    NetPremium: NetPremium,
    GST: GST,
    FinalPremium: FinalPremium,
    Year: end - start === 0 ? "1 Year" : end - start + " Years",
    MinMaxIdv: MinIdv + " - " + MaxIdv,
    idv: idv,
    insurer: insurer,
    discount: discount,
    StartDate: StartDate,
    EndDate: EndDate,
    policyType: policyType,
    RoadSideAssistance: RoadSideAssistance,
    TyreProtection: TyreProtection,
    RimProtection: RimProtection,
    Consumables: Consumables,
    EngineGearBox: EngineGearBox,
    EngineProtection: EngineProtection,
    IsElectricalAccessories: IsElectricalAccessories,
    IsNonElectricalAccessories: IsNonElectricalAccessories,
    InvoiceCover: InvoiceCover,
    VoluntaryDeductive: VoluntaryDeductive,
    NCBProtection: NCBProtection,
    PassengerCover: PassengerCover,
    LossOfPersonalBelongings: LossOfPersonalBelongings,
    ZeroDepriciationCover: ZeroDepriciationCover,
    KeyReplacement: KeyReplacement,
    PaCoverAmount: PaCoverAmount,
    PassengerCoverAmount: PassengerCoverAmount,
    ElectricAmount: ElectricAmount,
    nonElectricalAmount: nonElectricalAmount,
    VoluntaryAmount: VoluntaryAmount,
    NCBDiscount: NCBDiscount,
    CNGValue: CNGValue,
    DriverLiability: DriverLiability,
  };
};

// let postData = {
//   required_insurance_company: insurer,
//       require_idv: idv,
//       require_discount: discount,
//       expected_final_premium: FinalPremium,
//       policyId: store.getState().root.policyId,
//       policy_starts: StartDate,
//       policy_expires: EndDate,
//       policy_issue: StartDate,
//       policy_recieve: StartDate,
//       od_net_premium: BasicODPremium,
//       terrorism_premium: BasicTPPremium,
//       tax_amount: GST,
//       net_premium: NetPremium
// }

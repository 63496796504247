import moment from "moment";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";

// import $ from "jquery";
const QuotePolicyDetails = ({ submitPolicyDetails = () => {}, insurerData, regYear, isNewVehicle }) => {
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    watch: watch1,
    formState: { errors: errors1 },
  } = useForm({ mode: "onBlur" });
  // useEffect(() => {
  //   $(function () {
  //     $('[data-toggle="datepicker"]').datepicker({
  //       autoHide: true,
  //       zIndex: 2048,
  //     });
  //   });
  // }, []);
  const previousPolicyKnow = useRef(null);
  previousPolicyKnow.current = watch1("PolicyStatus");

  const ManufaturingDate = useRef(null);
  ManufaturingDate.current = watch1("ManufaturingDate");

  const RegistrationDate = useRef(null);
  RegistrationDate.current = watch1("RegistrationDate");
  let minDate =
    previousPolicyKnow.current === "continue"
      ? moment().format(`YYYY-MM-DD`)
      : previousPolicyKnow.current === "expired within 90 day"
      ? moment().subtract(90, "day").format(`YYYY-MM-DD`)
      : "";
  let maxDate =
    previousPolicyKnow.current === "continue"
      ? moment().add(90, "day").format(`YYYY-MM-DD`)
      : moment().format(`YYYY-MM-DD`);

  return (
    <form className="mt-5 pt-lg-5" onSubmit={handleSubmit1(submitPolicyDetails)}>
      <div className="row">
        {isNewVehicle === false && (
          <div className="col-lg-4 col-12 position-relative  mb-3">
            <div className="form-floating">
              <select
                name="PolicyStatus"
                {...register1("PolicyStatus", {
                  required: "Field is required",
                })}
                className="form-select"
                id="idxx0"
              >
                <option className="d-none" selected>
                  Select
                </option>
                <option value="continue">Continue</option>
                <option value="expired within 90 day">Expired Within 90 days</option>
                <option value="expired above 90 day">Expired Above 90 days</option>
                <option value="false">No Information About Previous Policy</option>
              </select>
              <label htmlFor="idxx0">Select Policy Type</label>
            </div>
            <p className="f-error">{errors1?.PolicyStatus?.message}</p>
          </div>
        )}

        <div className="col-lg-4 col-12 position-relative  mb-3">
          <div className="form-floating">
            <select
              name="NewPolicyType"
              {...register1("NewPolicyType", {
                required: "Field is required",
              })}
              className="form-select"
              id="idxx1"
            >
              <option className="d-none" selected>
                Select
              </option>
              <option value="Comprehensive">Comprehensive</option>
              <option value="ThirdParty">Third Party</option>
              <option value="ODOnly">StandAlone OD</option>
            </select>
            <label htmlFor="idxx1">New policy type?</label>
          </div>
          <p className="f-error">{errors1?.NewPolicyType?.message}</p>
        </div>
        {isNewVehicle === false && (
          <>
            <div className="col-lg-4 col-md-6 col-12 position-relative mb-3">
              <div className="form-floating">
                <select
                  name="PreviousPolicyType"
                  {...register1("PreviousPolicyType", {
                    required: "Field is required",
                  })}
                  className="form-select"
                  id="idxx2"
                >
                  <option className="d-none" selected>
                    Select
                  </option>
                  <option value="Comprehensive">Comprehensive</option>
                  <option value="ThirdParty">Third Party</option>
                  <option value="ODOnly">StandAlone OD</option>
                  <option value="Bundled">Bundled</option>
                </select>
                <label htmlFor="idxx2">Previous policy type?</label>
              </div>
              <p className="f-error" />
            </div>

            <div className="col-lg-4 col-md-6 col-12 position-relative mb-3">
              <div className="form-floating">
                <select
                  name="PreInsurerCode"
                  {...register1("PreInsurerCode", {
                    required: "Field is required",
                  })}
                  className="form-select"
                  id="idxx3"
                >
                  <option className="d-none" selected>
                    Select
                  </option>
                  {insurerData &&
                    insurerData.length > 0 &&
                    insurerData.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.option}
                      </option>
                    ))}
                </select>
                <label htmlFor="idxx3">Previous Policy Insurer</label>
              </div>
              <p className="f-error">{errors1?.PreInsurerCode?.message}</p>
            </div>
          </>
        )}

        <div
          className={
            isNewVehicle === false
              ? "col-lg-4 col-12 position-relative mb-3"
              : "col-lg-4 col-12 position-relative  mb-3"
          }
        >
          <div className="form-floating mb-3 ">
            <input
              {...register1("ManufaturingDate", {
                required: "Field is required",
              })}
              name="ManufaturingDate"
              type="date"
              id="idxx4"
              className=" form-control w-100"
              data-date-format="mm/dd/yyyy"
              defaultValue={moment().startOf("month").format(`${regYear}-MM-DD`)}
            />
            <label htmlFor="idxx4">Mfg. month of the year</label>
          </div>
          {/* <p className="f-error">{errors1?.ManufaturingDate?.message}</p> */}
        </div>

        <div className="col-lg-4 col-12 position-relativev mb-3">
          <div className="form-floating">
            <input
              {...register1("RegistrationDate", {
                required: "Field is required",
              })}
              name="RegistrationDate"
              type="date"
              id="idxx5"
              className=" form-control w-100"
              data-date-format="mm/dd/yyyy"
              defaultValue={
                isNewVehicle === false
                  ? moment().add(1, "day").format(`${regYear}-MM-DD`)
                  : isNewVehicle === true
                  ? moment().format(`${regYear}-MM-DD`)
                  : ""
              }
              min={ManufaturingDate.current}
              max={moment(ManufaturingDate.current, "YYYY-MM-DD").add(1, "year").format("YYYY-MM-DD")}
            />
            <label htmlFor="idxx5">Reg. date of your car</label>
          </div>
          <p className="f-error">{errors1?.RegistrationDate?.message}</p>
        </div>

        {isNewVehicle == false && (
          <div className="col-lg-4 col-12 position-relative mb-3">
            <div className="form-floating">
              <input
                {...register1("PrePolicyEndDate", {
                  required: "Field is required",
                })}
                name="PrePolicyEndDate"
                type="date"
                min={minDate}
                max={maxDate}
                id="idxx6"
                className="datepicker form-control w-100"
                data-date-format="mm/dd/yyyy"
                defaultValue
              />
              <label htmlFor="idxx6">Prev. year policy expiry date</label>
            </div>
            <p className="f-error">{errors1?.PrePolicyEndDate?.message}</p>
          </div>
        )}

        <div className="col-lg-4 col-12 position-relative mb-3">
          <div className="form-floating">
            <select
              name="CustomerType"
              {...register1("CustomerType", {
                required: "Field is required",
              })}
              className="form-select"
              id="idxx7"
            >
              <option className="d-none" selected>
                Select
              </option>
              <option value="INDIVIDUAL">Individual</option>
              <option value="COMPANY">Organization</option>
            </select>
            <label htmlFor="idxx7">Vehicle Owned By</label>
          </div>
          <p className="f-error">{errors1?.CustomerType?.message}</p>
        </div>
        {isNewVehicle === false && (
          <>
            <div className="col-lg-4 col-12 position-relative">
              <div className="form-floating">
                <select
                  name="PreviousNoClaimBonus"
                  {...register1("PreviousNoClaimBonus", {
                    required: "Field is required",
                  })}
                  className="form-select"
                  id="idxx8"
                >
                  <option className="d-none" selected>
                    Select
                  </option>
                  <option value={0}>0%</option>
                  <option value={20}>20%</option>
                  <option value={25}>25%</option>
                  <option value={35}>35%</option>
                  <option value={45}>45%</option>
                  <option value={50}>50%</option>
                </select>
                <label htmlFor="idxx8">Previous No Claim Bonus (NCB)</label>
              </div>
              <p className="f-error">{errors1?.PreviousNoClaimBonus?.message}</p>
            </div>

            <div className="col-lg-4 col-12 position-relative">
              <div className="form-floating">
                <select
                  {...register1("PreviousInsuranceClaimed", {
                    required: "Field is required",
                  })}
                  name="PreviousInsuranceClaimed"
                  className="form-select"
                  id="idxx9"
                >
                  <option className="d-none" selected>
                    Select
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                <label htmlFor="idxx9">Claim made in expiring policy?</label>
              </div>
              <p className="f-error">{errors1?.PreviousInsuranceClaimed?.message}</p>
            </div>

            <div className="col-lg-12 px-0 text-center">
              <button type="submit" className="btn btn-primary fs-4 mt-5 px-5 py-3 ">
                Get Prefered Quote
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default QuotePolicyDetails;

import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <section className="footer-section">
        <div className="address-section">
          <div className="logo-name">
            <h1>Expert Cover</h1>
          </div>
          <div className="content-address">
            <img src="./assets/img/e13.jpg" alt="" />
            <div className="contentText">
              <h1>Location</h1>
              <h5>MI Road, 334, Jaipur, 302021, India</h5>
            </div>
          </div>

          <div className="content-address">
            <img src="./assets/img/e14.jpg" alt="" />
            <div className="contentText">
              <h1>Email Address</h1>
              <h5>support@expertCover.in</h5>
            </div>
          </div>

          <div className="content-address">
            <img src="./assets/img/e15.jpg" alt="" />
            <div className="contentText">
              <h1>Phone</h1>
              <h5>+91(9485 9485 16)</h5>
            </div>
          </div>
        </div>

        <div className="insurance-section">
          <div className="insurance-plans_investment">
            <h1>Life Insurance</h1>
            <a href="#">
              <p>Life Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Calculator</p>
            </a>
            <a href="#">
              <p>1 Crore Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Return Of</p>
            </a>
            <a href="#">
              <p>Premium</p>
            </a>
            <a href="#">
              <p>Term Insurance For</p>
            </a>
            <a href="#">
              <p>Housewife</p>
            </a>
            <a href="#">
              <p>Spouse Term Plan</p>
            </a>
          </div>

          <div className="insurance-plans_investment">
            <h1>Health Insurance</h1>
            <a href="#">
              <p>Life Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Calculator</p>
            </a>
            <a href="#">
              <p>1 Crore Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Return Of</p>
            </a>
            <a href="#">
              <p>Premium</p>
            </a>
            <a href="#">
              <p>Term Insurance For</p>
            </a>
            <a href="#">
              <p>Housewife</p>
            </a>
            <a href="#">
              <p>Spouse Term Plan</p>
            </a>
          </div>

          <div className="insurance-plans_investment">
            <h1>Investment</h1>
            <a href="#">
              <p>Life Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Calculator</p>
            </a>
            <a href="#">
              <p>1 Crore Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Return Of</p>
            </a>
            <a href="#">
              <p>Premium</p>
            </a>
            <a href="#">
              <p>Term Insurance For</p>
            </a>
            <a href="#">
              <p>Housewife</p>
            </a>
            <a href="#">
              <p>Spouse Term Plan</p>
            </a>
          </div>

          <div className="insurance-plans_investment">
            <h1>General Insurance</h1>
            <a href="#">
              <p>Life Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Calculator</p>
            </a>
            <a href="#">
              <p>1 Crore Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Return Of</p>
            </a>
            <a href="#">
              <p>Premium</p>
            </a>
            <a href="#">
              <p>Term Insurance For</p>
            </a>
            <a href="#">
              <p>Housewife</p>
            </a>
            <a href="#">
              <p>Spouse Term Plan</p>
            </a>
          </div>

          <div className="insurance-plans_investment">
            <h1>Other Insurance</h1>
            {/* <ul> */}
            <a href="#">
              <p>Life Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Insurance</p>
            </a>
            <a href="#">
              <p>Calculator</p>
            </a>
            <a href="#">
              <p>1 Crore Term Insurance</p>
            </a>
            <a href="#">
              <p>Term Return Of</p>
            </a>
            {/* </ul> */}
          </div>
        </div>

        <div className="links-section">
          <a href="">
            <p>Home</p>
          </a>
          <a href="">
            <p>About us</p>
          </a>
          <a href="">
            <p>SiteMap</p>
          </a>
          <a href="">
            <p>Careers</p>
          </a>
          <a href="">
            <p>INSP</p>
          </a>
          <a href="">
            <p>Insurance Companies</p>
          </a>
          <a href="">
            <p>Articles</p>
          </a>
          <a href="">
            <p>Contact Us</p>
          </a>
          <a href="">
            <p>Know your Advisor</p>
          </a>
          <a href="">
            <p>Newsroom</p>
          </a>
          <a href="">
            <p>Security</p>
          </a>
        </div>

        <div className="payment-links-section">
          <img src="./assets/img/pay.png" alt="" />
        </div>
      </section>
      <div className="expert-cover-insurance">
        © 2023. Expert Cover Insurance Company. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
